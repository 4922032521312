import React from 'react'
import {Layout, Row, Table, Tag, Col, Button, Space, Popconfirm, message} from 'antd';
import {API_URI,TOKENADMIN} from "../../../constants/ApiConfig";
import {Link} from 'react-router-dom';
import axios from "axios";

const {Content} = Layout;
//const axios = require('axios');


class ListePacks extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            listeData: [],
            catPays: this.props.cat,
            idPays: this.props.idPays,
            idSejour: this.props.idSejour,
            pathPage: this.props.pathPanel ? this.props.pathPanel : '/packs/edit/',
            dataPays: null,
            dataSejour: null,
            loading: false,

        }


    }


    /**
     * Obtenir la data de l'api pour la liste des pays
     *
     * @param cat
     * @returns {Promise<void>}
     * http://localhost:3000/Pays/Panel
     */
    apiListePays = async (cat, ids) => {
        let url = null
        if (this.state.idSejour) {
            url = API_URI + '/api/gp_packs?cat=' + cat + '&idSejour=' + ids;
        } else {
            url = API_URI + '/api/gp_packs?cat=' + cat + '&ids=' + ids;
        }
        let dataApi = await axios.get(url,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        let dataReponse = dataApi.data['hydra:member'];

        let dataReturn = [];

        let pathName = this.state.pathPage;

        dataReponse.map((data) =>
            dataReturn.push({
                nom_pack:
                    <Link className="btn btn-primary" to={`${pathName}${data.id}`}>
                        {data.nom}
                    </Link>,
                sem_benevolat: data.benevolat,
                sem_langue: data.langue,
                prix_ht: data.prixht,
                action: <Space size="middle">
                    <Popconfirm
                        title="Are you sure delete this task?"
                        onConfirm={(e,idPack) =>this.confirm(e,data.id)}
                        onCancel={this.cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a href="#">Effacer</a>
                    </Popconfirm>
                </Space>,
            })
        );

        this.setState({listeData: dataReturn})

    }

    loadData = async ()=> {
        let id = null;
        if (this.state.idSejour != null) {
            id = this.state.idSejour
            await this.detailsSejour(this.state.idSejour)
            await this.apiListePays(this.state.dataSejour.cat, id)
        } else {
            id = this.state.idPays
            await this.detailsPaysSejour(this.state.idPays)
            await this.apiListePays(this.state.dataPays.cat, id)
        }


        this.setState({
            loading: true,
        })
    }
    async componentDidMount() {

        this.loadData()


    }


    detailsPaysSejour = async (idPays) => {

        let dataApi = await axios.get(API_URI + '/api/gp_pays_sejours/' + idPays,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        this.setState({
            dataPays: dataApi.data,
        })


    }

    detailsSejour = async (idSejour) => {

        let dataApi = await axios.get(API_URI + '/api/gp_sejours/' + idSejour,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        this.setState({
            dataSejour: dataApi.data,
        })
    }


    deletePack = async (idPack) => {

        let dataApi = await axios.delete(API_URI + '/api/gp_packs/' + idPack,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        await this.loadData()

        return true
    }

    confirm(e,idPack) {
        this.deletePack(idPack)
        message.success('Le pack a bien été supprimé');
    }

    cancel(e) {
        console.log(e);
        message.error('Annulation de la supression du pack');
    }

    render() {

        if (this.state.loading) {

            const columns = [
                {
                    title: 'Nom du pack',
                    dataIndex: 'nom_pack',
                    key: 'nom_pack',
                    render: text => <div>{text}</div>,
                },
                {
                    title: 'Semaine(s) bénévolat',
                    dataIndex: 'sem_benevolat',
                    key: 'sem_benevolat',
                },
                {
                    title: 'Semaine(s) langue',
                    dataIndex: 'sem_langue',
                    key: 'sem_langue',
                },
                {
                    title: 'Prix HT',
                    dataIndex: 'prix_ht',
                    key: 'prix_ht',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                },

            ];

            return (
                <div>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >

                        <Table columns={columns} dataSource={this.state.listeData}/>

                    </Content>
                </div>
            )
        } else {
            return (
                <div>Chargement...</div>
            )
        }

    }
}


export default ListePacks;


