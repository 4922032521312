import React from 'react'
import {connect} from 'react-redux';
import {Layout, Menu} from 'antd';
import {RightOutlined, DashboardOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const {Sider} = Layout;


class MenuSider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div>

                <Sider width={200} className="site-layout-background">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        style={{height: '100%', borderRight: 0}}
                    >
                        <Menu.Item key="1" icon={<DashboardOutlined/>}>
                            <Link className="btn btn-primary" to="/dashboard">
                                DashBoard
                            </Link>
                        </Menu.Item>


                        <Menu.Item key="2" icon={<RightOutlined/>}>
                            <Link className="btn btn-primary" to="/Pays">
                                Pays / Programmes
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="4" icon={<RightOutlined/>}>
                            <Link className="btn btn-primary" to="/pages">
                                Pages CMS
                            </Link>
                        </Menu.Item>

                        {
                            (!localStorage.getItem('emailAdmin') || localStorage.getItem('emailAdmin') !== 'association@globalong.com') && (
                                <>
                        <Menu.Item key="8" icon={<RightOutlined/>}>
                            <Link className="btn btn-primary" to="/packs">
                                Packs
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="5" icon={<RightOutlined/>}>
                            <Link className="btn btn-primary" to="/dates">
                                Dates
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="7" icon={<RightOutlined/>}>
                            <Link className="btn btn-primary" to="/supplements">
                                Suppléments
                            </Link>
                        </Menu.Item>
                                </>
                            )
                        }

                        <Menu.Item key="7" icon={<RightOutlined/>}>
                            <Link className="btn btn-primary" to="/parametres/home">
                                Paramètres
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="6" icon={<RightOutlined/>}>
                            <Link className="btn btn-primary" to="/exit">
                                Déconnexion
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
            </div>

        );
    }
}


export default MenuSider

