import React from 'react'
import {
    Breadcrumb,
    Layout,
    Col,
    Row,
    Form,
    Checkbox,
    DatePicker,
    Select,
    Button,
    Input,
    PageHeader,
    Card,
    InputNumber
} from 'antd';
import {API_URI, TOKENADMIN} from "../../../constants/ApiConfig";
import {Redirect} from "react-router-dom";
import HeaderTop from "../../HeaderTop";
import MenuSider from "../../MenuSider";
import axios from "axios";
//const axios = require('axios');
const {Content} = Layout;
const {RangePicker} = DatePicker;
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 5},
};
const tailLayout = {
    wrapperCol: {offset: 4, span: 5},
};

const {Option} = Select;

class AddSupp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: Number(this.props.match.params.id),
            type: this.props.match.params.type,
            loading: true,
            cat: Number(this.props.match.params.cat),
            idSejour: Number(this.props.match.params.idSejour),
        }

    }


    async componentDidMount() {
console.log(this.state)
    }

    insertPack = async (values) => {

        //sejour

        let data = {
            "cat": this.state.cat,
            "nom": values.nom,
            "prixht": values.prixht,
            "temps": values.temps,
            "nomEn": "",
            "idsejour": this.state.id,
        }

        // ajouter le bon id si c'est un séjour ou un pays
        if (this.state.type == 'pays') {
            data.ids = this.state.id
        } else if (this.state.type == 'sejour') {
            data.idSejourOk = "/api/gp_sejours/" + this.state.idSejour
        }

        if (values.tempsdates) {
            data.datea = values.tempsdates[0]
            data.dateb = values.tempsdates[1]
        }


        const res = await axios.post(API_URI + '/api/gp_supplements', data, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }


    }

    onFinish = async values => {

        await this.insertPack(values)

        this.setState({
            redirect: '/supplements/panel/' + this.state.cat + '/' + this.state.id
        })

//idSejour
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        if (this.state.loading) {
            return (
                <Layout style={{minHeight: '100vh'}}>
                    <HeaderTop/>
                    <Layout>
                        <MenuSider/>
                        <Layout style={{padding: '0 24px 24px'}}>
                            <div>

                                <Breadcrumb style={{margin: '16px 0'}}>
                                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item>Packs</Breadcrumb.Item>
                                    <Breadcrumb.Item>Panel</Breadcrumb.Item>
                                    <Breadcrumb.Item>Ajouter un supplément</Breadcrumb.Item>
                                </Breadcrumb>

                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 24,
                                        margin: 0,
                                        minHeight: 280,
                                    }}
                                >
                                    <PageHeader
                                        className="site-page-header"
                                        onBack={() => window.history.back()}
                                        title="Ajouter un supplément"
                                        subTitle="Associer un supplément à un séjour ou pays"
                                    />
                                    <Card>
                                        <Form
                                            {...layout}
                                            name="basic"
                                            initialValues={{remember: true}}
                                            onFinish={this.onFinish}
                                            onFinishFailed={this.onFinishFailed}
                                        >
                                            <Form.Item
                                                label="Nom du pack"
                                                name="nom"
                                                rules={[{
                                                    required: true,
                                                    message: 'Merci de bien vouloir compléter cette information.'
                                                }]}
                                            >
                                                <Input/>
                                            </Form.Item>

                                            <Form.Item
                                                label="Prix"
                                                name="prixht"
                                                rules={[{
                                                    required: true,
                                                    message: 'Merci de bien vouloir compléter cette information.'
                                                }]}
                                            >
                                                <InputNumber/>
                                            </Form.Item>

                                            <Form.Item
                                                label="Prix par"
                                                name="temps"
                                                rules={[{
                                                    required: true,
                                                    message: 'Merci de bien vouloir compléter cette information.'
                                                }]}
                                            >
                                                <Select style={{width: 120}}>
                                                    <Option value="semaine">Semaine</Option>
                                                    <Option value="fixe">Fixe</Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Restriction par date"
                                                name="tempsdates"
                                                rules={[{
                                                    type: 'array',
                                                    required: false,
                                                }]}
                                            >
                                                <RangePicker/>
                                            </Form.Item>


                                            <Form.Item {...tailLayout}>
                                                <Button type="primary" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                </Content>
                            </div>
                        </Layout>
                    </Layout>
                </Layout>
            )
        } else {
            return (
                'Chargement...'
            )
        }


    }
}


export default AddSupp;


