import React from 'react'
import {Breadcrumb, Layout, Col, Row, Form, Input, Button, Checkbox, Typography, message} from 'antd';
import PropTypes from 'prop-types'
import {getCat} from "../../actions";
import getToken from "../api/getToken";
import getTokenAPi from "../api/authApi";
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import {Redirect} from "react-router-dom";


//const axios = require('axios');
import axios from "axios";
const {Content} = Layout;

class IdentUser extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            unspalshResponse: false,
            url: "",
        }
    }

    async componentDidMount() {
        await localStorage.removeItem('tokenAdmin')
        await this.getUnsplash()
    }

    getUnsplash = async () => {

        try {
            const response = await axios.get('https://api.unsplash.com/photos/random/?client_id=16b1f5aeced2f198bd6cfb172a951862d1870e1b78e0a64fee6080d488e4fa8b&query=travel,city&orientation=landscape', {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });

            this.setState(
                {
                    loading: true,
                    unspalshResponse: response,
                    url: response.data.urls.raw
                })
        } catch (error) {
            this.setState(
                {
                    loading: true,
                    unspalshResponse: '',
                    url: ''
                })
        }

    }

    /**
     *
     * @param user
     * @param passe
     * @returns {Promise<void>}
     */
    async getNewToken(user, passe) {
        await axios.post(API_URI + '/api/authentication_token', {
            "email": user,
            "password": passe
        }).then(response => {
            message.success('Authentification réussite ;)', 3);
            localStorage.setItem('tokenAdmin', response.data.token);
            localStorage.setItem('emailAdmin', user);
            this.setState({
                redirect: '/dashboard'
            })
        }).catch(error => {
            message.error('Votre utilisateur ou mot de passe est incorrect.', 3);
        });

    }

    formValIdent = async (value) => {
        console.log(value.password)
        await this.getNewToken(value.username, value.password);
    }


    render() {
        const layoutForm = {
            labelCol: {span: 5},
            wrapperCol: {span: 17},
        };
        const tailLayout = {
            wrapperCol: {offset: 5, span: 17},
        };
        const {Title} = Typography;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        if (this.state.loading) {
            return (
                <Layout style={{minHeight: "100vh"}}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 0,
                            margin: 0,
                            minHeight: 400,
                            width: "100%",
                            backgroundImage: "url(" + this.state.url + ")",
                            backgroundPosition: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >

                        <div style={{}}>
                            <div style={{
                                width: "700px",
                                height: "auto",
                                padding: "30px",
                                backgroundColor: "#FFFFFF",
                            }}>
                                <div style={{textAlign: "center"}}>
                                    <Title level={3}>Globalong - Site DashBoard</Title>
                                </div>

                                <Form
                                    {...layoutForm}
                                    name="basic"
                                    initialValues={{remember: true}}
                                    onFinish={this.formValIdent}

                                >
                                    <Form.Item
                                        label="Utilisateur"
                                        name="username"
                                        rules={[{required: true, message: 'Please input your username!'}]}
                                    >
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        label="Mot de passe"
                                        name="password"
                                        rules={[{required: true, message: 'Please input your password!'}]}
                                    >
                                        <Input.Password/>
                                    </Form.Item>

                                    <Form.Item {...tailLayout}>
                                        <Button type="primary" htmlType="submit">
                                            Valider
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>

                    </Content>
                </Layout>
            )
        } else {
            return (
                'Chargement...'
            )
        }


    }
}

export default IdentUser;


