import React from 'react'
import {Breadcrumb, Layout, Col, Row, Tabs, Card, Alert, Result, Button, Progress, Typography, Spin} from 'antd';
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";
import PropTypes from 'prop-types'
import {getCat} from "../../actions";
import getToken from "../api/getToken";

import {API_URI, TOKENADMIN,URL} from "../../constants/ApiConfig";
import {LoadingOutlined} from '@ant-design/icons';
import axios from "axios";
//const axios = require('axios');
const {Content} = Layout;
const {Title} = Typography;

class Index extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            start: <Result
                title="Lancement du processus"
                extra={
                    <Button type="primary" key="console" onClick={this.startProcess}>
                        Démarrer la purge et la génération du classement
                    </Button>
                }
            />
        }
    }

    async componentDidMount() {


    }

    startProcess = async () => {

        let contentHtml = <div>
            <Title level={2}>Processus en cours de lancement</Title>
            <LoadingOutlined
                style={{
                    fontSize: 24,
                }}
                spin
            />

            <Alert message="Processus en cours de lancement" type="warning" />
        </div>

        this.setState({start: contentHtml})

        var dataReturn = await axios.get(URL+'/tree/generate');

        if (dataReturn) {
            let contentHtml = <Result
                status="success"
                title="Le processus de génération est lancé !"
                subTitle="Un email a été envoyé pour signalé son démarrage et un autre sera envoyé quand le processus sera terminé d'ici 20 min approximativement."
            />

            this.setState({start: contentHtml})
        }
    }


    render() {
        return (
            <Layout style={{minHeight: '100vh'}}>
                <HeaderTop/>
                <Layout>
                    <MenuSider/>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <Content
                            className="site-layout-background"
                            style={{
                                padding: 24,
                                margin: 0,
                                minHeight: 280,
                            }}
                        >

                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Classement filtre" key="1">
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Card title="Mise à jour du classement filtre" bordered={false}>
                                                <p><Alert
                                                    message="Mise à jour du classement"
                                                    description="Le traitement dure en moyenne 20 minutes, à la fin du traitement un email est envoyé à sophie@globalong.com et morgan@webexpertis.com en CC afin de signaler que le processus est terminé. Attention il ne faut pas lancer plusieurs fois le processus sinon il faudra attendre le dernier email (20 min après avoir lancé celui-ci) pour le relancer proprement car le classement sera erroné. Il sera prévu dans les prochain dev de ne pas rendre possible un lancement multiple. La génération du classement étant sensible (attributs etc...) il est préférable de le faire si nécessaire (changement important d'attributs)"
                                                    type="info"
                                                    showIcon
                                                /></p>
                                                <div style={{
                                                    textAlign: 'center',
                                                }}>
                                                    {this.state.start}
                                                </div>

                                            </Card>
                                        </Col>
                                    </Row>
                                </Tabs.TabPane>

                            </Tabs>

                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}

export default Index;


