import React from 'react'
import {
    Breadcrumb,
    Layout,
    Form,
    DatePicker,
    Button,
    Input,
    PageHeader,
    Card,
    InputNumber,
    Tabs,
    Select,
    message
} from 'antd';
import {API_URI, TOKENADMIN} from "../../../constants/ApiConfig";
import {Redirect} from "react-router-dom";
import HeaderTop from "../../HeaderTop";
import MenuSider from "../../MenuSider";
import axios from "axios";
const {TabPane} = Tabs;
//const axios = require('axios');
const {Content} = Layout;
const {Option} = Select;

const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 5},
};
const tailLayout = {
    wrapperCol: {offset: 4, span: 5},
};


class AddDate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: Number(this.props.match.params.id),
            type: this.props.match.params.type,
            loading: true,
            cat: Number(this.props.match.params.cat),
            idSejour: Number(this.props.match.params.idSejour),
        }

    }


    async componentDidMount() {


        console.log(this.state)


    }

    insertPack = async (values, typeForm) => {

        //sejour

        console.log(values)

        let data = {

            "cat": this.state.cat,
            "type": null
        }

        // ajouter le bon id si c'est un séjour ou un pays
        if (this.state.type == 'pays') {
            data.idsejour = this.state.id
        } else if (this.state.type == 'sejour') {
            data.idSejourLink = "/api/gp_sejours/" + this.state.idSejour
        }

        // ajout de la recurrence
        if (typeForm == 'fixe') {
            data.recc = "off"
            data.reccNum = 0
            data.reccDay = ""
            data.reccMonth = ""
            data.reccDaynum = null
            data.reccYear = 0

            data.langue = values.langue
            data.benevolat = values.benevolat
            data.dateDebut = values.dateDebut
            data.dateFin = values.dateFin
            data.prixht = values.prixhHt
        } else {
            // alors recurrent
            data.recc = "on"
            data.reccNum = Number(values.reccNum)
            data.reccDay = values.reccDay
            data.reccMonth = values.reccMonth
            data.reccYear = 0
            data.reccDaynum = null
            data.langue = 0
            data.benevolat = 0
            data.prixht = 0
        }


        message.success('La date a bien été ajoutée', 10);

        const res = await axios.post(API_URI + '/api/gp_dates', data, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }

    onFinishFixe = async values => {

        await this.insertPack(values, 'fixe')

        /*
        this.setState({
            redirect: '/dates/panel/' + this.state.cat + '/' + this.state.id
        })
*/

    };

    onFinishRecc = async values => {

        if (values.reccNum == 0 && values.reccDay == 0 && values.reccMonth == 0) {
            message.error('Il n\'est pas possible de mettre "Tous" de partout.', 10);
            return false;
        } else if (values.reccNum !== 0 && values.reccDay == 0 && values.reccMonth == 0) {
            message.error('La récurrence du "mois" ou/et "de chaque" aussi n\'est pas cohérent ', 10);
            return false;
        } else if (values.reccNum == 0 && values.reccDay == 0 && values.reccMonth !== 0) {
            message.error('La récurrence du "Le" et "de chaque" aussi n\'est pas cohérent.', 10);
            return false;
        } else {
            await this.insertPack(values, 'recc')
            this.setState({
                redirect: '/dates/panel/' + this.state.cat + '/' + this.state.id
            })
        }
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        if (this.state.loading) {
            return (
                <Layout style={{minHeight: '100vh'}}>
                    <HeaderTop/>
                    <Layout>
                        <MenuSider/>
                        <Layout style={{padding: '0 24px 24px'}}>
                            <div>

                                <Breadcrumb style={{margin: '16px 0'}}>
                                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item>Dates</Breadcrumb.Item>
                                    <Breadcrumb.Item>Panel</Breadcrumb.Item>
                                    <Breadcrumb.Item>Ajouter une date</Breadcrumb.Item>
                                </Breadcrumb>


                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 24,
                                        margin: 0,
                                        minHeight: 280,
                                    }}
                                >
                                    <PageHeader
                                        className="site-page-header"
                                        onBack={() => window.history.back()}
                                        title="Ajouter une date"
                                        subTitle="Associer une date à un séjour ou pays"
                                    />
                                    <Card>

                                        <Tabs defaultActiveKey="1" tabPosition="left">
                                            <TabPane tab="Pogrammation fixe" key="1">
                                                <Form
                                                    {...layout}
                                                    name="fixe"
                                                    initialValues={{remember: true}}
                                                    onFinish={this.onFinishFixe}
                                                    onFinishFailed={this.onFinishFailed}
                                                >

                                                    <Form.Item
                                                        label="Date de début"
                                                        name="dateDebut"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <DatePicker/>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Date de fin"
                                                        name="dateFin"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <DatePicker/>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Semaine(s) bénévolat"
                                                        name="benevolat"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <InputNumber/>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Semaine(s) langue"
                                                        name="langue"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <InputNumber/>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Prix HT"
                                                        name="prixhHt"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <InputNumber/>
                                                    </Form.Item>


                                                    <Input type="text" type="hidden" value="off"/>


                                                    <Form.Item {...tailLayout}>
                                                        <Button type="primary" htmlType="submit">
                                                            Submit
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </TabPane>
                                            <TabPane tab="Pogrammation réccurente" key="2">
                                                <Form
                                                    {...layout}
                                                    name="recc"
                                                    initialValues={{remember: true}}
                                                    onFinish={this.onFinishRecc}
                                                    onFinishFailed={this.onFinishFailed}
                                                >

                                                    <Form.Item
                                                        label="Le"
                                                        name="reccNum"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <Select defaultValue="" style={{width: 120}}>
                                                            <option value=""></option>
                                                            <option value="1">1 er</option>
                                                            <option value="2">2 eme</option>
                                                            <option value="3">3 eme</option>
                                                            <option value="4">4 eme</option>
                                                            <option value="5">5 eme</option>
                                                            <option value="0">Tous</option>
                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="De chaque"
                                                        name="reccDay"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <Select defaultValue="" style={{width: 120}}>
                                                            <option value=""></option>
                                                            <option value="Monday">Lundi</option>
                                                            <option value="Tuesday">Mardi</option>
                                                            <option value="Wednesday">Mercredi</option>
                                                            <option value="Thursday">Jeudi</option>
                                                            <option value="Friday">Vendredi</option>
                                                            <option value="Saturday">Samedi</option>
                                                            <option value="Sunday">Dimanche</option>
                                                            <option value="0">Tous</option>

                                                        </Select>
                                                    </Form.Item>

                                                    <Form.Item
                                                        label="Du mois de "
                                                        name="reccMonth"
                                                        rules={[{
                                                            required: true,
                                                            message: 'Merci de bien vouloir compléter cette information.'
                                                        }]}
                                                    >
                                                        <Select defaultValue="" style={{width: 120}}>
                                                            <option value=""></option>
                                                            <option value="1">Janvier</option>
                                                            <option value="2">Février</option>
                                                            <option value="3">Mars</option>
                                                            <option value="4">Avril</option>
                                                            <option value="5">Mai</option>
                                                            <option value="6">Juin</option>
                                                            <option value="7">Juillet</option>
                                                            <option value="8">Août</option>
                                                            <option value="9">Septembre</option>
                                                            <option value="10">Octobre</option>
                                                            <option value="11">Novembre</option>
                                                            <option value="12">Décembre</option>
                                                            <option value="0">Tous</option>
                                                        </Select>
                                                    </Form.Item>


                                                    <Form.Item {...tailLayout}>
                                                        <Button type="primary" htmlType="submit">
                                                            Submit
                                                        </Button>
                                                    </Form.Item>
                                                </Form>
                                            </TabPane>
                                        </Tabs>

                                    </Card>
                                </Content>
                            </div>
                        </Layout>
                    </Layout>
                </Layout>
            )
        } else {
            return (
                'Chargement...'
            )
        }


    }
}


export default AddDate;


