import React from 'react'
import BlocTxt from '../blocs/BlocTxt'
import BlocImages from "../blocs/BlocImages";
import GeneralSeo from "./Form/GeneralSeo"
import ListeProgrammes from "./ListeProgrammes";
import AttributsSejours from "../attributs/AttributsSejours";
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";

import {
    Divider,
    Breadcrumb,
    Layout,
    Tabs,
    PageHeader,
    Card,
    Typography,
    Skeleton,
    Spin,
    Button,
    Anchor, Col, Row,
} from 'antd';
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import {getCats} from "../../actions";
import FormAddSejour from "./Form/FormAddSejour";
import axios from "axios";
const {Link} = Anchor;
const {Content} = Layout;
const {TabPane} = Tabs;
const {Title, Text} = Typography;
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 14},
};
const tailLayout = {
    wrapperCol: {offset: 4, span: 22},
};
//const axios = require('axios');


class PaysPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            loading: false,
            dataPaysSejour: null,
            loadingApiData: true,
            nameCat: null,

        }
    }


    detailsPaysSejour = async () => {

        let dataApi = await axios.get(API_URI + '/api/gp_pays_sejours/' + this.state.id, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        this.setState({
            dataPaysSejour: dataApi.data,
            loading: true,
        })


    }

    async componentDidMount() {

        await this.detailsPaysSejour()


        const {getCats, nameCatok} = this.props;
        let resultCat = getCats(this.state.dataPaysSejour.cat)
        this.setState({
            nameCat: resultCat.nameCat,
            permaLien: resultCat.permaLien,
        })
    }


    render() {


        if (this.state.loading == false) {
            return (<Skeleton active/>)
        } else {

            return (
                <Layout style={{minHeight: '100vh'}}>
                    <HeaderTop/>
                    <Layout>
                        <MenuSider/>
                        <Layout style={{padding: '0 24px 24px'}}>
                            <div>
                                <Breadcrumb style={{margin: '16px 0'}}>
                                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item>{this.state.nameCat}</Breadcrumb.Item>
                                    <Breadcrumb.Item>Pays</Breadcrumb.Item>
                                    <Breadcrumb.Item>{this.state.dataPaysSejour.nomPays}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{this.state.dataPaysSejour.h1Fr}</Breadcrumb.Item>
                                    <Breadcrumb.Item>Panel</Breadcrumb.Item>
                                </Breadcrumb>
                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 24,
                                        margin: 0,
                                        minHeight: 280,
                                    }}
                                >
                                    <div className="site-page-header-ghost-wrapper">
                                        <PageHeader
                                            ghost={false}
                                            onBack={() => window.history.back()}
                                            title={this.state.dataPaysSejour.h1Fr}
                                            subTitle={this.state.nameCat}
                                            extra={[
                                                <Button key="1" type="link"
                                                        href={API_URI + "/" + this.state.permaLien + "/" + this.state.dataPaysSejour.permalienFr + ".html"}
                                                        target="_blank">
                                                    Visiter la page sur le site
                                                </Button>,
                                            ]}
                                        >
                                        </PageHeader>
                                    </div>

                                    <Card>
                                        <Tabs defaultActiveKey="1">
                                            <TabPane tab="Seo & titre" key="1">
                                                <Row>
                                                    <Col span={4}>
                                                        <AttributsSejours
                                                            titleListe="Continent"
                                                            idPays={this.state.id}
                                                            typeAttribut={5}
                                                            templateAttribut="template_lib_continent"
                                                            type="pays"
                                                        />
                                                    </Col>
                                                    <Col span={4}>
                                                        <AttributsSejours
                                                            titleListe="Langue parlée"
                                                            idPays={this.state.id}
                                                            typeAttribut={7}
                                                            templateAttribut="template_lib_langue"
                                                            type="pays"
                                                        />
                                                    </Col>
                                                    <Col span={14}>
                                                        <GeneralSeo id={this.state.id}/>
                                                    </Col>
                                                </Row>

                                            </TabPane>


                                            <TabPane tab="Textes" key="2">

                                                <Row>

                                                    <Col span={18}>

                                                        <Title id="anchor_intro" level={2}>Introduction</Title>
                                                        <Text type="secondary">Bloc Texte introduction</Text>
                                                        <Divider/>

                                                        <BlocTxt
                                                            title="TItre du paragraphe"
                                                            description=""
                                                            inputName="text_1_titre"
                                                            type="brute"
                                                            uniqKey="text_1_titre"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <BlocTxt
                                                            title="Texte sous le titre"
                                                            description=""
                                                            inputName="text_1_txt"
                                                            type="richText"
                                                            uniqKey="text_1_txt"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />


                                                        <Divider/>
                                                        <Title id="anchor_txtsousmeteo" level={2}>Texte sous la
                                                            météo</Title>
                                                        <Text type="secondary">Bloc Texte qui se situe juste en dessous
                                                            de la météo avec une
                                                            video à droite</Text>
                                                        <Divider dashed="true"/>

                                                        <BlocTxt
                                                            title="Titre"
                                                            description=""
                                                            inputName="text_2_titre"
                                                            type="brute"
                                                            uniqKey="text_2_titre"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <BlocTxt
                                                            title="Bloc texte"
                                                            description=""
                                                            inputName="text_2_txt"
                                                            type="richText"
                                                            uniqKey="text_2_txt"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />


                                                        <Divider/>
                                                        <Title id="anchor_accordeon" level={2}>Accordéon</Title>
                                                        <Text type="secondary">Accordéon qui est composé de plusieurs
                                                            partie Programme lieux
                                                            etc...</Text>
                                                        <Divider dashed="true"/>

                                                        <BlocTxt
                                                            title="Faq : bloc gauche"
                                                            description=""
                                                            inputName="accordeon_faq_1"
                                                            uniqKey="accordeon_faq_1"
                                                            type="richText"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <BlocTxt
                                                            title="Faq : bloc droite"
                                                            description=""
                                                            inputName="accordeon_faq_2"
                                                            uniqKey="accordeon_faq_2"
                                                            type="richText"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />


                                                        <BlocTxt
                                                            title="Les lieux : Description"
                                                            description=""
                                                            inputName="accordeon_programme_4"
                                                            uniqKey="accordeon_programme_4"
                                                            type="richText"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <BlocTxt
                                                            title="Le climat"
                                                            description=""
                                                            inputName="accordeon_climat_1"
                                                            uniqKey="accordeon_climat_1"
                                                            type="richText"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <BlocTxt
                                                            title="Témoignages"
                                                            description=""
                                                            inputName="accordeon_temoignages_1"
                                                            uniqKey="accordeon_temoignages_1"
                                                            type="richText"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <BlocTxt
                                                            title="Loisirs/Temps libre : Infos lieux à visiter"
                                                            description=""
                                                            inputName="accordeon_programme_5"
                                                            uniqKey="accordeon_programme_5"
                                                            type="richText"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />
                                                        <BlocTxt
                                                            title="Sécurité : Description"
                                                            description=""
                                                            inputName="accordeon_programme_6"
                                                            uniqKey="accordeon_programme_6"
                                                            type="richText"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <Divider/>
                                                        <Title id="anchor_slogan" level={2}>Slogan</Title>
                                                        <Text type="secondary">Slogan</Text>
                                                        <Divider dashed="true"/>

                                                        <BlocTxt
                                                            title="Slogan qui est en millieu de page juste au dessus des séjours"
                                                            description=""
                                                            inputName="slogan_1"
                                                            uniqKey="slogan_1"
                                                            type="brute"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                        <Divider/>
                                                        <Title id="anchor_galerie_flickr" level={2}>Accordéon : gallerie
                                                            Flickr</Title>
                                                        <Text type="secondary">Gestion du lien flickr dans la
                                                            gallerie</Text>
                                                        <Divider dashed="true"/>

                                                        <BlocTxt
                                                            title="Code HTML Flickr"
                                                            description="Insérer le code complet ici"
                                                            inputName="gal_flickr"
                                                            type="brute"
                                                            uniqKey="gal_flickr"
                                                            typeIndex="idPaysSejour"
                                                            id={this.state.id}

                                                        />

                                                    </Col>

                                                    <Col span={6} style={{paddingLeft: "1em"}}>
                                                        <Anchor>

                                                            <Link href="#anchor_intro" title="Introduction">
                                                                <Link href="#anchor_text_1_titre"
                                                                      title="TItre du paragraphe"/>
                                                                <Link href="#anchor_text_1_txt"
                                                                      title="Texte sous le titre"/>
                                                            </Link>

                                                            <Link href="#anchor_txtsousmeteo"
                                                                  title="Texte sous la météo">
                                                                <Link href="#anchor_text_2_titre" title="Titre"/>
                                                                <Link href="#anchor_text_2_txt" title="Bloc texte"/>
                                                            </Link>

                                                            <Link href="#anchor_accordeon" title="Accordéon">
                                                                <Link href="#anchor_accordeon_programme_4"
                                                                      title="Les lieux : Description"/>
                                                                <Link href="#anchor_accordeon_climat_1"
                                                                      title="Programme : Climat"/>

                                                                <Link href="#anchor_accordeon_temoignages_1"
                                                                      title="Témoignages"/>



                                                                <Link href="#anchor_accordeon_programme_5"
                                                                      title="Loisirs/Temps libre : Infos lieux à visiter"/>
                                                                <Link href="#anchor_accordeon_programme_6"
                                                                      title="Sécurité : Description"/>
                                                                <Link href="#anchor_galerie_flickr"
                                                                      title="Galerie : Flickr"/>
                                                            </Link>
                                                            <Link href="#anchor_slogan" title="Slogan"/>

                                                        </Anchor>
                                                    </Col>
                                                </Row>

                                            </TabPane>
                                            <TabPane tab="Images" key="3">


                                                <BlocImages
                                                    uniqKey="bg_header"
                                                    height={675}
                                                    width={1920}
                                                    title="Background du titre"
                                                    description="L'image d'arrière plan qui est dans le header avec le titre"
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg=""

                                                />

                                                <Divider/>
                                                <Title level={2}>Vidéo ou photo texte introduction</Title>
                                                <Text type="secondary">Si la vidéo est vide la photo prendra le dessus
                                                    si elle n'est pas vide</Text>
                                                <Divider dashed="true"/>

                                                <BlocImages
                                                    uniqKey="photo_intro_1"
                                                    height={240}
                                                    width={370}
                                                    title="Photo à droite du texte"
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg=""

                                                />

                                                <BlocTxt
                                                    title="Lien de la video youtube"
                                                    description=""
                                                    inputName="video_intro_1"
                                                    uniqKey="video_intro_1"
                                                    type="brute"
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}

                                                />

                                                <Divider/>
                                                <Title level={2}>Vidéo ou photo texte sous la météo</Title>
                                                <Text type="secondary">Si la vidéo est vide la photo prendra le dessus
                                                    si elle n'est pas vide</Text>
                                                <Divider dashed="true"/>

                                                <BlocImages
                                                    uniqKey="photo_txt_2"
                                                    height={240}
                                                    width={370}
                                                    title="Photo à droite du texte"
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg=""

                                                />

                                                <BlocTxt
                                                    title="Lien de la video youtube"
                                                    description=""
                                                    inputName="video_txt_2"
                                                    uniqKey="video_txt_2"
                                                    type="brute"
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}

                                                />

                                                <Divider/>
                                                <Title level={2}>Accordéon : images</Title>
                                                <Text type="secondary">Images présentes dans l'accordéon</Text>
                                                <Divider dashed="true"/>

                                                <BlocImages
                                                    uniqKey="acc_loisir_1"
                                                    height={240}
                                                    width={370}
                                                    title="Loisirs / Temps libre : image 1"
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg=""

                                                />

                                                <BlocImages
                                                    uniqKey="acc_climat_1"
                                                    height={240}
                                                    width={370}
                                                    title="Climat : image 1"
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg=""

                                                />

                                                <BlocImages
                                                    uniqKey="acc_climat_2"
                                                    height={240}
                                                    width={370}
                                                    title="Climat : image 1"
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg=""

                                                />

                                                <Divider/>
                                                <Title level={2}>Accordéon : gallerie</Title>
                                                <Text type="secondary">Gallerie dans l'accordéon</Text>
                                                <Divider dashed="true"/>

                                                <BlocImages
                                                    uniqKey="gal_1"
                                                    height={240}
                                                    width={370}
                                                    title=""
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg="gal1"

                                                />

                                                <BlocImages
                                                    uniqKey="gal_2"
                                                    height={240}
                                                    width={370}
                                                    title=""
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg="gal1"

                                                />

                                                <BlocImages
                                                    uniqKey="gal_3"
                                                    height={240}
                                                    width={370}
                                                    title=""
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg="gal1"

                                                />

                                                <BlocImages
                                                    uniqKey="gal_4"
                                                    height={240}
                                                    width={370}
                                                    title=""
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg="gal1"

                                                />

                                                <BlocImages
                                                    uniqKey="gal_5"
                                                    height={240}
                                                    width={370}
                                                    title=""
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg="gal1"

                                                />

                                                <BlocImages
                                                    uniqKey="gal_6"
                                                    height={240}
                                                    width={370}
                                                    title=""
                                                    description=""
                                                    typeIndex="idPaysSejour"
                                                    id={this.state.id}
                                                    groupImg="gal1"

                                                />


                                            </TabPane>

                                            <TabPane tab="Programmes" key="4">

                                                <FormAddSejour data={this.state.dataPaysSejour}/>

                                                <ListeProgrammes idCat={this.state.dataPaysSejour.cat}
                                                                 codePays={this.state.dataPaysSejour.codePays}/>

                                            </TabPane>

                                            <TabPane tab="Attributs pays" key="5">

                                                <AttributsSejours
                                                    titleListe="Attributs pays"
                                                    idPays={this.state.id}
                                                    typeAttribut={1}
                                                    templateAttribut="template_pays_infos"
                                                    type="paysParent"
                                                    valueInsert={true}
                                                    codePays={this.state.dataPaysSejour.codePays}
                                                />

                                                <Divider/>

                                                <AttributsSejours
                                                    titleListe="Attributs pays séjour"
                                                    idPays={this.state.id}
                                                    typeAttribut={9}
                                                    templateAttribut="template_pays_sejour_infos"
                                                    type="pays"
                                                    valueInsert={true}
                                                />


                                            </TabPane>
                                        </Tabs>
                                    </Card>

                                </Content>
                            </div>
                        </Layout>
                    </Layout>
                </Layout>
            );
        }


    }
}

export default PaysPanel;


