import React from 'react'
import {Layout, Row, Table, Tag, Col, Button} from 'antd';
import {API_URI,TOKENADMIN} from "../../constants/ApiConfig";
import {Link} from 'react-router-dom';
import axios from "axios";
const {Content} = Layout;
//const axios = require('axios');

const columns = [
    {
        title: 'Page',
        dataIndex: 'libelle',
        key: 'libelle',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
];


class Liste extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            listeData: [],
            catPays: this.props.cat,
            pathPage: '/pages/panel/'
        }


    }


    apiListePays = async (cat) => {

        let dataApi = await axios.get(API_URI + '/api/pages',{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        let dataReponse = dataApi.data['hydra:member'];

        let dataReturn = [];

        let pathName = this.state.pathPage;

        dataReponse.map((data) =>
            dataReturn.push({
                libelle:
                    <Link className="btn btn-primary" to={`${pathName}${data.id}`}>
                        {data.libelle}
                    </Link>,
                description: data.description,
            })
        );

        this.setState({listeData: dataReturn})

    }

    componentDidMount() {
        this.apiListePays(this.state.catPays)
    }

    render() {

        return (
            <div>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >
                    <Table columns={columns} dataSource={this.state.listeData}/>

                </Content>
            </div>
        );
    }
}


export default Liste;


