import { connect } from 'react-redux'
import Dashboard from '../components/parametres/Index'
import {blocText, getCats, incrementAction} from '../actions'
import Index from "../components/parametres/Index";

const mapStateToProps = (state) => ({
    value: state.value,
    geodok: state.geodok,
    nameCatok: state.nameCatok,
});

const mapDispatchToProps = (dispatch) => ({
    incrementAction: () => dispatch(incrementAction()),
    blocText: (x) => dispatch(blocText(x)),
    getCats: (x) => dispatch(getCats(x)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)