const initialState = {
    value: 0,
    nameCat: 'No'
};

function addReducer(state = initialState, action) {

    switch (action.type) {
        case 'INCREMENT':
            return {...state, value: state.value + 1};
        case 'CREATBLOCTXT' :
            let nestedState = action.geod;
            return {...state, geodok: nestedState, geodoks: state.geodok};

        case 'CAT' :
            let nestedStates = action.nameCat;
            let permaLien = action.permaLien;
            return {...state, nameCatok: nestedStates,permaLien:permaLien};


        default:
            return state;
    }
}

export default addReducer;
