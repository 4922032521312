import React from 'react'
import {
    Layout,
    Button,
    Table,
    Tag,
    Space,
    Divider,
    Row,
    Col,
    Form,
    Input,
    Card,
    Typography,
    message,
    Skeleton
} from 'antd';
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import {Editor} from "@tinymce/tinymce-react";
import axios from "axios";
const { Paragraph } = Typography;

const {Content} = Layout;
//const axios = require('axios');
const {Title, Text} = Typography;

const key = 'updatable';

const openMessage = () => {
    message.loading({content: 'Loading...', key});
    setTimeout(() => {
        message.success({content: 'Loaded!', key, duration: 2});
    }, 1000);
};


class BlocTxt extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            description: this.props.description,
            inputName: this.props.inputName,
            type: this.props.type,
            uniqKey: this.props.uniqKey,
            id: this.props.id,
            idBdd: '',
            typeIndex: this.props.typeIndex,
            dataSubmit: '',
            loadApi: false,
            valueEnter: "",
            loading: false,
            btnValidate: false,
            info: false,

        }
    }


    testTextExist = async () => {

        let dataApi = await axios.get(API_URI + '/api/site_textes?' + this.state.typeIndex + '=' + this.state.id + '&uniqKey=' + this.state.uniqKey, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        let dataReponse = dataApi.data['hydra:member'];
        let totalDataReponse = dataApi.data['hydra:totalItems'];

        return {
            'total': totalDataReponse,
            'data': dataReponse,
        }
    }

    creationBlocText = async () => {

        if (this.state.typeIndex === 'idPaysSejour') {
            const res = await axios.post(API_URI + '/api/site_textes', {
                "texteFr": "",
                "idPaysSejour": "/api/gp_pays_sejours/" + this.state.id,
                "type": this.state.type,
                "uniqKey": this.state.uniqKey,
                "info": this.state.description
            }, {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });

            return {
                'data': res.data,
            }
        } else if (this.state.typeIndex === 'idSejour') {
            const res = await axios.post(API_URI + '/api/site_textes', {
                "texteFr": "",
                "idSejour": "/api/gp_sejours/" + this.state.id,
                "type": this.state.type,
                "uniqKey": this.state.uniqKey,
                "info": this.state.description
            }, {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });

            return {
                'data': res.data,
            }
        } else if (this.state.typeIndex === 'page') {
            const res = await axios.post(API_URI + '/api/site_textes', {
                "texteFr": "",
                "page": "/api/pages/" + this.state.id,
                "type": this.state.type,
                "uniqKey": this.state.uniqKey,
                "info": this.state.description
            }, {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });

            return {
                'data': res.data,
            }
        }


    }

    loadMessage = () => {
        const key = 'updatable';
        message.loading({content: 'Loading...', key});
        setTimeout(() => {
            message.success({content: 'Loaded!', key, duration: 2});
        }, 1000);
    }

    // mise à jour d'un bloc texte
    updateInput = async () => {

        this.loadMessage()

        const res = await axios.put(API_URI + '/api/site_textes/' + this.state.idBdd, {
            "texteFr": this.state.dataSubmit.texteFr
        }, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }


    onFinish = values => {

        this.setState({
            'dataSubmit': {
                "texteFr": values.val
            }
        })

        this.updateInput()

    };

    onFinishRichText = values => {
        //event.preventDefault();

        this.setState({
            'dataSubmit': {
                "texteFr": this.state.valueEnter
            }
        })

        this.updateInput()

    };

    onUpdateRichText = (content, editor) => {

        this.setState({
            valueEnter: content,
            btnValidate: <Form.Item>
                <Button type="primary" htmlType="submit">
                    Valider
                </Button>
            </Form.Item>
        })
    };


    formRef = React.createRef();
    componentDidMount = async () => {

        // je vérifie si le bloc est déjà existant
        let testBlocTxt = await this.testTextExist()


        // si la réponse est une data à 0 nous créons
        if (testBlocTxt.total === 0) {
            let creaBloc = await this.creationBlocText()
            this.setState({
                idBdd: creaBloc.data.id,
                value: creaBloc.data.texteFr,
                info: creaBloc.data.info,
                loading: true,
            })

        } else {

            await this.setState({
                idBdd: testBlocTxt.data[0].id,
                value: testBlocTxt.data[0].texteFr,
                info: testBlocTxt.data[0].info,
                loading: true,
            })


        }

        /*
        this.formRef.current.setFieldsValue({
            val: this.state.value,
        });
*/

    }


    render() {

        if (this.state.loading == false) {
            return (<Skeleton active/>)
        } else {
            if (this.state.type == 'brute') {

                return (

                    <Row>
                        <Col span={24}>
                            <Title id={"anchor_" + this.state.uniqKey} level={4}>{this.state.title}</Title>
                            <Text copyable={{ text: '{% if "'+this.state.uniqKey+'" in btxt|keys %}{{ btxt["'+this.state.uniqKey+'"]|raw }}{% endif %}' }}>{this.state.description} - Tag {this.state.uniqKey} - {this.state.info}</Text>
                            <Form onFinish={this.onFinish} ref={this.formRef}>
                                <Form.Item name="val" initialValue={this.state.value}>
                                    <Input/>
                                </Form.Item>

                                <br />
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Valider
                                    </Button>
                                </Form.Item>
                                {this.state.btnValidate}
                            </Form>
                        </Col>

                    </Row>

                );
            } else if (this.state.type == 'richText') {
                return (

                    <Row>

                        <Col span={24}>
                            <Title copyable={{ text: '{% if "'+this.state.uniqKey+'" in btxt|keys %}{{ btxt["'+this.state.uniqKey+'"]|raw }}{% endif %}' }} id={"anchor_" + this.state.uniqKey} level={4}>{this.state.title} -
                                Tag {this.state.uniqKey} - {this.state.info}</Title>
                            <Text>{this.state.description}</Text>

                            <Form onFinish={this.onFinishRichText} ref={this.formRef}>
                                <Editor
                                    apiKey="f3afuix2w4xvi9pdvox283cdhovckqohpv7xybzuxgmbl94i"
                                    initialValue={this.state.value}
                                    textareaName='val'
                                    id={this.state.uniqKey}
                                    init={{
                                        height: 300,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount',
                                            'textcolor code fontsize'
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | fontselect fontsizeselect | bold italic backcolor underline | forecolor \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help | fullscreen | link | paste pastetext code '
                                    }}
                                    onEditorChange={this.onUpdateRichText}
                                />

                                {this.state.btnValidate}

                            </Form>
                        </Col>

                    </Row>

                );
            }
        }


    }
}


export default BlocTxt;


