import React from "react";
import {Button, Form, Input, message, Skeleton} from "antd";
import {API_URI,TOKENADMIN} from "../../../constants/ApiConfig";
import axios from "axios";
//const axios = require('axios');
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 14},
};
const tailLayout = {
    wrapperCol: {offset: 4, span: 22},
};

class GeneralSeo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingApiData: true,
            id: this.props.id,
            dataPaysSejour: null,

        }
    }

    apiDataPaysSejours = async (id) => {

        let dataApi = await axios.get(API_URI + '/api/gp_pays_sejours/' + id,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});
        let dataReponse = dataApi.data;
        this.setState({dataPaysSejour: dataReponse, loadingApiData: false})

    }

    componentDidMount() {
        this.apiDataPaysSejours(this.state.id);
    }

    loadMessage = () => {
        const key = 'updatable';
        message.loading({ content: 'Loading...', key });
        setTimeout(() => {
            message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
    }

    updateForm = async (value) => {



        const res = await axios.put(API_URI + '/api/gp_pays_sejours/' + this.state.id, {
            "h1Fr": value.seo.h1Fr,
            "t1Fr": value.seo.t1Fr,
            "titleFr": value.seo.titleFr,
            "permalienFr": value.seo.permalienFr,
            "metaDescFr": value.seo.metaDescFr
        }, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        this.loadMessage()

        return {
            'data': res.data,
        }
    }

    render() {

        return (
            <div>
                {this.state.loadingApiData ? (
                    <Skeleton active/>
                ) : (
                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={this.updateForm}
                    >
                        <Form.Item
                            label="Permalien"
                            name={['seo', 'permalienFr']}
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataPaysSejour.permalienFr}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Meta Title"
                            name={['seo', 'titleFr']}
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataPaysSejour.titleFr}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Meta Description"
                            name={['seo', 'metaDescFr']}
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataPaysSejour.metaDescFr}
                        >
                            <Input.TextArea/>
                        </Form.Item>

                        <Form.Item
                            label="Titre H1"
                            name={['seo', 'h1Fr']}
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataPaysSejour.h1Fr}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Sous-titre"
                            name={['seo', 't1Fr']}
                            rules={[{required: true, message: 'Merci de compléter cette information'}]}
                            initialValue={this.state.dataPaysSejour.t1Fr}
                        >
                            <Input/>
                        </Form.Item>


                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                )}

            </div>
        )
    }
}


export default GeneralSeo;