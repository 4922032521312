import React from 'react'

import {Layout, Menu} from 'antd';

const {Header} = Layout;

const HeaderTop = () => (
    <Header className="header">
        <div className="logo"/>
        <Menu theme="dark" mode="horizontal" >
            <Menu.Item key="1">
                <a href="https://www.globalong.com/" target="_blank" rel="noopener noreferrer">
                    SITE
                </a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href="https://crm.globalong.com/" target="_blank" rel="noopener noreferrer">
                    CRM
                </a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href="https://www.globalong.com/blog" target="_blank" rel="noopener noreferrer">
                    BLOG
                </a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href="https://www.globalong.net/" target="_blank" rel="noopener noreferrer">
                    RESEAU BLOG
                </a>
            </Menu.Item>
        </Menu>
    </Header>

)

export default HeaderTop
