import React from 'react'
import {
    Breadcrumb,
    Layout,
    Col,
    Row,
    Form,
    Checkbox,
    Button,
    Input,
    PageHeader,
    Card,
    InputNumber,
    message
} from 'antd';
import {API_URI, TOKENADMIN} from "../../../constants/ApiConfig";
import {Redirect} from "react-router-dom";
import HeaderTop from "../../HeaderTop";
import MenuSider from "../../MenuSider";
import axios from "axios";
//const axios = require('axios');
const {Content} = Layout;

const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 5},
};
const tailLayout = {
    wrapperCol: {offset: 4, span: 5},
};


class EditPack extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: Number(this.props.match.params.id),
            loading: false,
            dataPack: false,
            cat: this.props.cat,
            idPays: this.props.idPays
        }

    }


    async componentDidMount() {

        const res = await axios.get(API_URI + '/api/gp_packs/' + this.state.id, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        await this.setState({dataPack: res.data, loading: true})


    }

    insertPack = async (values) => {

        //sejour

        let data = {
            "cat": this.state.cat,
            "nom": values.namePack,
            "nomEn": "",
            "prixht": values.prixhHt,
            "langue": values.langue,
            "benevolat": values.benevolat
        }


        message.success('Le pack a bien été mis à jour', 10);

        const res = await axios.put(API_URI + '/api/gp_packs/' + this.state.id, data, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }

    onFinish = async values => {

        await this.insertPack(values)

        /*
        this.setState({
            redirect: '/packs/panel/' + this.state.cat + '/' + this.state.idPays
        })

         */
    };

    onFinishFailed = errorInfo => {
    };


    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        if (this.state.loading) {
            return (
                <Layout style={{minHeight: '100vh'}}>
                    <HeaderTop/>
                    <Layout>
                        <MenuSider/>
                        <Layout style={{padding: '0 24px 24px'}}>
                            <div>

                                <Breadcrumb style={{margin: '16px 0'}}>
                                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item>Packs</Breadcrumb.Item>
                                    <Breadcrumb.Item>Panel</Breadcrumb.Item>
                                    <Breadcrumb.Item>Editer un pack</Breadcrumb.Item>
                                </Breadcrumb>


                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 24,
                                        margin: 0,
                                        minHeight: 280,
                                    }}
                                >
                                    <PageHeader
                                        className="site-page-header"
                                        onBack={() => window.history.back()}
                                        title="Editer un pack"
                                        subTitle="Modifier le pack pour un pays ou séjour."
                                    />
                                    <Card>
                                        <Form
                                            {...layout}
                                            name="basic"
                                            initialValues={{remember: true}}
                                            onFinish={this.onFinish}
                                            onFinishFailed={this.onFinishFailed}
                                        >
                                            <Form.Item
                                                label="Nom du pack"
                                                name="namePack"
                                                rules={[{
                                                    required: true,
                                                    message: 'Merci de bien vouloir compléter cette information.'
                                                }]}
                                                initialValue={this.state.dataPack.nom}
                                            >
                                                <Input/>
                                            </Form.Item>

                                            <Form.Item
                                                label="Semaine(s) bénévolat"
                                                name="benevolat"
                                                rules={[{
                                                    required: true,
                                                    message: 'Merci de bien vouloir compléter cette information.'
                                                }]}
                                                initialValue={this.state.dataPack.benevolat}
                                            >
                                                <InputNumber/>
                                            </Form.Item>

                                            <Form.Item
                                                label="Semaine(s) langue"
                                                name="langue"
                                                rules={[{
                                                    required: true,
                                                    message: 'Merci de bien vouloir compléter cette information.'
                                                }]}
                                                initialValue={this.state.dataPack.langue}
                                            >
                                                <InputNumber/>
                                            </Form.Item>

                                            <Form.Item
                                                label="Prix HT"
                                                name="prixhHt"
                                                rules={[{
                                                    required: true,
                                                    message: 'Merci de bien vouloir compléter cette information.'
                                                }]}
                                                initialValue={this.state.dataPack.prixht}
                                            >
                                                <InputNumber/>
                                            </Form.Item>


                                            <Form.Item {...tailLayout}>
                                                <Button type="primary" htmlType="submit">
                                                    Mettre à jour
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Card>
                                </Content>
                            </div>
                        </Layout>
                    </Layout>
                </Layout>

            )
        } else {
            return (
                'Chargement...'
            )
        }


    }
}


export default EditPack;


