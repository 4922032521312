import React from 'react'
import {Layout, Row, Table, Tag, Col, Button} from 'antd';
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import {Link} from 'react-router-dom';
import axios from "axios";
const {Content} = Layout;
//const axios = require('axios');

const columns = [
    {
        title: 'Pays',
        dataIndex: 'nomPays',
        key: 'nomPays',
        render: text => <a>{text}</a>,
    },
    {
        title: 'Titre',
        dataIndex: 'titrePays',
        key: 'titrePays',
    },
    {
        title: 'Code',
        dataIndex: 'codePays',
        key: 'codePays',
    },
    {
        title: 'Etat',
        key: 'tagsPays',
        dataIndex: 'tagsPays',
        render: tagsPays => (
            <>
                {tagsPays.map(tag => {

                    let color = 'volcano';
                    if (tag === 'ACTIF') {
                        color = 'green';
                    }
                    return (
                        <Tag color={color} key={tag}>
                            {tag.toUpperCase()}
                        </Tag>
                    );
                })}
            </>
        ),
    },
];

const data = [
    {
        key: '1',
        name: 'Afrique du sud',
        age: 'Titre ici',
        address: 'AFS',
        tags: ['ACTIF'],
    },
    {
        key: '2',
        name: 'Afrique du sud',
        age: 'Titre ici',
        address: 'AFS',
        tags: ['ACTIF'],
    },
    {
        key: '3',
        name: 'Afrique du sud',
        age: 'Titre ici',
        address: 'AFS',
        tags: ['DESACTIVE'],
    },
];


class Liste extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            listePaysData: [],
            catPays: this.props.cat,
            pathPage : this.props.pathPanel ? this.props.pathPanel : '/Pays/Panel/'
        }


    }


    /**
     * Obtenir la data de l'api pour la liste des pays
     *
     * @param cat
     * @returns {Promise<void>}
     * http://localhost:3000/Pays/Panel
     */
    apiListePays = async (cat) => {

        let dataApi = await axios.get(API_URI + '/api/listespays?order[nomPays]=asc&cat=' + cat,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});


        //http://globalong.webexpertis.com/toto.php

        let dataReponse = dataApi.data['hydra:member'];

        let dataReturn = [];

        let pathName = this.state.pathPage;

        dataReponse.map((data) =>
            dataReturn.push({
                nomPays:
                    <Link className="btn btn-primary" to={`${pathName}${data.id}`}>
                        {data.nomPays}
                    </Link>,
                titrePays: data.h1Fr,
                codePays: data.codePays,
                tagsPays: ['ACTIF'],
            })
        );

        this.setState({listePaysData: dataReturn})

    }

    componentDidMount() {
        this.apiListePays(this.state.catPays)
    }

    render() {

        return (
            <div>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >

                    <Table columns={columns} dataSource={this.state.listePaysData}/>

                </Content>
            </div>
        );
    }
}


export default Liste;


