import React from 'react'
import {
    Layout,
    Button,
    Table,
    Tag,
    Space,
    Divider,
    Row,
    Col,
    Form,
    Input,
    Card,
    Typography,
    message,
    Upload
} from 'antd';
import {API_URI, URL,TOKENADMIN} from "../../constants/ApiConfig";
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import axios from "axios";

//const axios = require('axios');
const {Title, Text,Paragraph} = Typography;
const tailLayout = {
    wrapperCol: {offset: 4, span: 22},
};
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 14},
};

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 20;

    if (!isLt2M) {
        message.error('Image must smaller than 20MB!');
    }
    return isJpgOrPng && isLt2M;
}

class BlocImages extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            title: this.props.title,
            desc: this.props.description,
            height: this.props.height,
            width: this.props.width,
            uniqKey: this.props.uniqKey,
            typeIndex: this.props.typeIndex,
            groupImg: this.props.groupImg,
            id: this.props.id,
            fileInfo: null,
            idBdd: null,
            info : false,
        }

    }

    /**
     * Gestion des évènements à l'upload
     * @param info
     * @returns {Promise<void>}
     */
    handleChange = async info => {
        if (info.file.status === 'uploading') {
            this.setState({loading: true});
            return;
        }
        if (info.file.status === 'done') {


            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );


            await this.setState({
                fileInfo: info.file.response
            })


            await this.updateInput()


        }
    };

    /**
     *
     * @returns {Promise<{total: *, data: *}>}
     */
    testImgExist = async () => {

        let dataApi = await axios.get(API_URI + '/api/site_images?' + this.state.typeIndex + '=' + this.state.id + '&uniqKey=' + this.state.uniqKey,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});
        let dataReponse = dataApi.data['hydra:member'];
        let totalDataReponse = dataApi.data['hydra:totalItems'];

        return {
            'total': totalDataReponse,
            'data': dataReponse,
        }

    }

    updateInput = async () => {

        console.log(this.state.idBdd)

        const res = await axios.put(API_URI + '/api/site_images/' + this.state.idBdd, {
            "name": this.state.fileInfo.fileName,
            "path": this.state.fileInfo.fullPathPublic,
        }, {
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }

    creationImage = async () => {

        if (this.state.typeIndex === 'idPaysSejour') {
            const res = await axios.post(API_URI + '/api/site_images', {
                "name": '',
                "path": '',
                "idPaysSejour": "/api/gp_pays_sejours/" + this.state.id,
                "info": "",
                "uniqKey": this.state.uniqKey,
                "width": this.state.width,
                "height": this.state.height,
                "seoName": "",
                "seoAlt": "",
                "seoTitle": "",
                "groupimg": this.state.groupImg,
            }, {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });

            this.setState({"idBdd": res.data.id})





            return {
                'data': res.data,
            }
        } else if (this.state.typeIndex === 'idSejour') {
            const res = await axios.post(API_URI + '/api/site_images', {
                "name": '',
                "path": '',
                "idSejour": "/api/gp_sejours/" + this.state.id,
                "info": "string",
                "uniqKey": this.state.uniqKey,
                "width": this.state.width,
                "height": this.state.height,
                "seoName": "",
                "seoAlt": "",
                "seoTitle": "",
                "groupimg": this.state.groupImg,
            }, {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });
            this.setState({"idBdd": res.data.id})


            return {
                'data': res.data,
            }
        } else if (this.state.typeIndex === 'page') {
            const res = await axios.post(API_URI + '/api/site_images', {
                "name": '',
                "path": '',
                "page": "/api/pages/" + this.state.id,
                "info": "string",
                "uniqKey": this.state.uniqKey,
                "width": this.state.width,
                "height": this.state.height,
                "seoName": "",
                "seoAlt": "",
                "seoTitle": "",
                "groupimg": this.state.groupImg,
            }, {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });
            this.setState({"idBdd": res.data.id})


            return {
                'data': res.data,
            }
        }
    }


    componentDidMount = async () => {

        // afficher image si il y a
        let testImg = await this.testImgExist()

        if (testImg.total !== 0) {
            this.setState({
                imageUrl: URL + '/' + testImg.data[0].path + '/' + testImg.data[0].name,
                idBdd: testImg.data[0].id,
                info : testImg.data[0].info,
            })

            // pré remplir le form
            this.formRef.current.setFieldsValue({
                seoName: testImg.data[0].seoName,
                seoAlt: testImg.data[0].seoAlt,
                seoTitle: testImg.data[0].seoTitle,
            });

        } else {
            console.log('POINTEUR')
            // création de l'image dans la bdd
            let creaImg = await this.creationImage()
            this.setState({
                info: creaImg.data.info,
            })

        }

    }

    loadMessage = () => {
        const key = 'updatable';
        message.success({ content: 'Mise à jour terminée avec succès !', key, duration: 2 });
    }

    updateSeo = async (value) => {

        this.loadMessage()

        const res = await axios.put(API_URI + '/api/site_images/' + this.state.idBdd, {
            "seoName": value.seoName,
            "seoAlt": value.seoAlt,
            "seoTitle": value.seoTitle,
        }, {
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }


    }

    deleteImage = async () => {
        let dataApi = await axios.delete(API_URI + '/api/site_images/' + this.state.idBdd,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        this.loadMessage()

    }

    formRef = React.createRef();

    render() {

        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined/> : <PlusOutlined/>}
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        const {imageUrl} = this.state;


        return (
            <div>
                <Title  level={4}>{this.state.title} - {this.state.uniqKey} - {this.state.info}</Title>
                <Text copyable={{tooltips: ['Code twig my_thumb_370_240', 'Code Twig copié'],text:'{% if bimg["'+this.state.uniqKey+'"] is defined %}<img src="{{ asset(\'/\' ~ bimg["'+this.state.uniqKey+'"]["fullPath"] ) | imagine_filter(\'my_thumb_370_240\') }}" />{% endif %}'}}> my_thumb_370_240</Text>
                <Text copyable={{tooltips: ['Code twig my_thumb_550_358', 'Code Twig copié'],text:'{% if bimg["'+this.state.uniqKey+'"] is defined %}<img src="{{ asset(\'/\' ~ bimg["'+this.state.uniqKey+'"]["fullPath"] ) | imagine_filter(\'my_thumb_550_358\') }}" />{% endif %}'}}> my_thumb_550_358</Text>
                <Text copyable={{tooltips: ['Code twig my_thumb_750_450', 'Code Twig copié'],text:'{% if bimg["'+this.state.uniqKey+'"] is defined %}<img src="{{ asset(\'/\' ~ bimg["'+this.state.uniqKey+'"]["fullPath"] ) | imagine_filter(\'my_thumb_750_450\') }}" />{% endif %}'}}> my_thumb_750_450</Text>
                <Text copyable={{tooltips: ['Code twig my_thumb_960_627', 'Code Twig copié'],text:'{% if bimg["'+this.state.uniqKey+'"] is defined %}<img src="{{ asset(\'/\' ~ bimg["'+this.state.uniqKey+'"]["fullPath"] ) | imagine_filter(\'my_thumb_960_627\') }}" />{% endif %}'}}> my_thumb_960_627</Text>
                <Text copyable={{tooltips: ['Code twig my_thumb_966_1104', 'Code Twig copié'],text:'{% if bimg["'+this.state.uniqKey+'"] is defined %}<img src="{{ asset(\'/\' ~ bimg["'+this.state.uniqKey+'"]["fullPath"] ) | imagine_filter(\'my_thumb_966_1104\') }}" />{% endif %}'}}> my_thumb_966_1104</Text>
                <Text copyable={{tooltips: ['Code twig my_thumb_1200_400', 'Code Twig copié'],text:'{% if bimg["'+this.state.uniqKey+'"] is defined %}<img src="{{ asset(\'/\' ~ bimg["'+this.state.uniqKey+'"]["fullPath"] ) | imagine_filter(\'my_thumb_1200_400\') }}" />{% endif %}'}}> my_thumb_1200_400</Text>
                <Text copyable={{tooltips: ['Code twig my_thumb_1200_400_v1', 'Code Twig copié'],text:'{% if bimg["'+this.state.uniqKey+'"] is defined %}<img src="{{ asset(\'/\' ~ bimg["'+this.state.uniqKey+'"]["fullPath"] ) | imagine_filter(\'my_thumb_1200_400_v1\') }}" />{% endif %}'}}> my_thumb_1200_400_v1</Text>

                <Text>{this.state.desc}</Text>
                <Divider/>
                <Row>

                    <Col span={10}>

                        <Form {...layout} onFinish={this.updateSeo} ref={this.formRef}>
                            <Form.Item
                                label="Nom"
                                name="seoName"
                                rules={[{
                                    required: true,
                                    message: 'Merci de compléter cette information'
                                }]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label="Balise ALT"
                                name="seoAlt"
                                rules={[{
                                    required: true,
                                    message: 'Merci de compléter cette information'
                                }]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                label="Balise TITLE"
                                name="seoTitle"
                                rules={[{
                                    required: true,
                                    message: 'Merci de compléter cette information'
                                }]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>

                            </Form.Item>
                        </Form>
                    </Col>

                    <Col span={3}>
                        <Upload
                            headers = {
                                {
                                    'Authorization' : 'bearer '+ TOKENADMIN
                                }
                            }
                            name="img"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={URL + "/api/images/upload"}
                            beforeUpload={beforeUpload}
                            onChange={this.handleChange}
                            data={
                                {
                                    'width': this.state.width,
                                    'height': this.state.height,
                                    'uniqKey': this.state.uniqKey
                                }
                            }

                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                        </Upload>
                        <Button type="dashed" danger onClick={this.deleteImage}>
                            Effacer l'image
                        </Button>
                    </Col>

                </Row>
            </div>
        );

    }
}


export default BlocImages;


