import React from 'react'
import {Layout, Row, Table, Tag, Col, Button, Space, Popconfirm, message} from 'antd';
import {API_URI,TOKENADMIN} from "../../../constants/ApiConfig";
import {Link} from 'react-router-dom';
import axios from "axios";
const {Content} = Layout;
//const axios = require('axios');

class ListeSupp extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            listeData: [],
            catPays: this.props.cat,
            idPays: this.props.idPays,
            idSejour: this.props.idSejour,
            pathPage: this.props.pathPanel ? this.props.pathPanel : '/Pays/Panel/',
            dataPays: null,
            dataSejour: null,
            loading: false,

        }


    }


    /**
     * Obtenir la data de l'api pour la liste des pays
     *
     * @param cat
     * @returns {Promise<void>}
     * http://localhost:3000/Pays/Panel
     */
    apiListePays = async (cat, ids) => {
        let url = null

        if (this.state.idSejour) {
            url = API_URI + '/api/gp_supplements?cat=' + cat + '&idSejourOk=' + ids;
        } else {
            // idsejour = id du pays je sais c'est débile mais erreur au début du dev :D suite à une modif de fonctionnement
            url = API_URI + '/api/gp_supplements?cat=' + cat + '&idsejour=' + ids;
        }
        let dataApi = await axios.get(url,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        let dataReponse = dataApi.data['hydra:member'];

        let dataReturn = [];

        let pathName = this.state.pathPage;

        dataReponse.map((data) =>
        {
            if (this.state.idSejour) {
                dataReturn.push({
                    libelle:
                        <Link className="btn btn-primary" to={`${pathName}${data.id}`}>
                            {data.nom}
                        </Link>,
                    type: data.temps,
                    date: data.date,
                    Mois: data.reccMonth,
                    date_debut: data.datea,
                    date_fin: data.dateb,
                    prix: data.prixht,

                    action: <Space size="middle">
                        <Popconfirm
                            title="Are you sure delete this task?"
                            onConfirm={(e,idSelect) =>this.confirm(e,data.id)}
                            onCancel={this.cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <a href="#">Effacer</a>
                        </Popconfirm>
                    </Space>,
                })
            }else{
                // si c'est un supp pays uniquement
                if(!data.idSejourOk){
                    dataReturn.push({
                        libelle:
                            <Link className="btn btn-primary" to={`${pathName}${data.id}`}>
                                {data.nom}
                            </Link>,
                        type: data.temps,
                        date: data.date,
                        Mois: data.reccMonth,
                        date_debut: data.datea,
                        date_fin: data.dateb,
                        prix: data.prixht,

                        action: <Space size="middle">
                            <Popconfirm
                                title="Are you sure delete this task?"
                                onConfirm={(e,idSelect) =>this.confirm(e,data.id)}
                                onCancel={this.cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <a href="#">Effacer</a>
                            </Popconfirm>
                        </Space>,
                    })
                }

            }

        }

        );

        this.setState({listeData: dataReturn})

    }

    loadData = async ()=> {
        let id = null;
        if (this.state.idSejour != null) {
            id = this.state.idSejour
            await this.detailsSejour(this.state.idSejour)
            await this.apiListePays(this.state.dataSejour.cat, id)
        } else {
            id = this.state.idPays
            await this.detailsPaysSejour(this.state.idPays)
            await this.apiListePays(this.state.dataPays.cat, id)
        }


        this.setState({
            loading: true,
        })
    }
    async componentDidMount() {

        this.loadData()

    }


    detailsPaysSejour = async (idPays) => {

        let dataApi = await axios.get(API_URI + '/api/gp_pays_sejours/' + idPays,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        this.setState({
            dataPays: dataApi.data,
        })


    }

    detailsSejour = async (idSejour) => {

        let dataApi = await axios.get(API_URI + '/api/gp_sejours/' + idSejour,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        this.setState({
            dataSejour: dataApi.data,
        })
    }


    deletePack = async (idSelect) => {

        let dataApi = await axios.delete(API_URI + '/api/gp_supplements/' + idSelect,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        await this.loadData()

        return true
    }

    confirm(e,idSelect) {
        this.deletePack(idSelect)
        message.success('Supression terminée');
    }

    cancel(e) {
        console.log(e);
        message.error('Annulation de la supression');
    }

    render() {

        if (this.state.loading) {

            const columns = [
                {
                    title: 'Libellé',
                    dataIndex: 'libelle',
                    key: 'libelle',
                    render: text => <a>{text}</a>,
                },
                {
                    title: 'Type',
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                },
                {
                    title: 'Date début',
                    dataIndex: 'date_debut',
                    key: 'date_debut',
                },
                {
                    title: 'Date fin',
                    dataIndex: 'date_fin',
                    key: 'date_fin',
                },
                {
                    title: 'Prix',
                    dataIndex: 'prix',
                    key: 'prix',
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                },

            ];

            return (
                <div>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >

                        <Table columns={columns} dataSource={this.state.listeData}/>

                    </Content>
                </div>
            )
        } else {
            return (
                <div>Chargement...</div>
            )
        }

    }
}


export default ListeSupp;


