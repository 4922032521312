import { connect } from 'react-redux'
import MenuSider from '../components/MenuSider'

import { incrementAction } from '../actions'

const mapStateToProps = (state) => ({
    value: state.value,
});




export default connect(
    mapStateToProps,

)(MenuSider)