import React, {Component} from 'react'
import {Tree, Row, Col, Typography, Alert, Input, Select} from 'antd';
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import axios from "axios";
const {TreeNode} = Tree;
//const axios = require('axios');
const {Title} = Typography;
const {Option} = Select;

class AttributsSejours extends Component {

    constructor(props) {
        super(props);

        this.state = {
            titleListe: (this.props.titleListe) ? this.props.titleListe : 'Liste des attributs',
            loading: false,
            idPays: Number(this.props.idPays),
            idGpPays: null,
            listeTree: [],
            typeAttribut: Number(this.props.typeAttribut),
            templateAttribut: (this.props.templateAttribut) ? this.props.templateAttribut : 'template_lib',
            idSejour: (this.props.idSejour) ? Number(this.props.idSejour) : null,
            type: (this.props.type) ? this.props.type : 'sejour',
            multiSelect: true,
            valueInsert: (this.props.valueInsert) ? this.props.valueInsert : false,
            valueZone: <Alert message="Cliquez sur un attribut" type="info"/>,
            codePays: (this.props.codePays) ? this.props.codePays : false,
            catId: (this.props.catId) ? this.props.catId : null,
        }
    }

    async componentDidMount() {

        if (this.state.type == 'paysParent') {
            await this.getIdGpPays(this.state.codePays)
            var idGpPays = await this.getIdGpPays(this.state.codePays)

            this.setState(
                {
                    idGpPays: idGpPays,
                }
            )
        }
        await this.creatLst()
        let idSelect = await this.getSelectItem()
        this.setState(
            {
                loading: true,
                dataSelect: idSelect.idLibSelect,
            }
        )
    }

    getIdGpPays = async codePays => {
        let dataApi = await axios.get(API_URI + '/api/gp_pays?codePays=' + this.state.codePays, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        })
        return dataApi.data['hydra:member'][0].id
    }

    getSelectItem = async () => {
        //tester les continents
        let test = await this.getAllAtribIdSejour(this.state.typeAttribut)
        let idSelectArray = []
        if (test.total > 0) {
            let dataValues = test.data;
            dataValues.map((item) =>
                idSelectArray.push(String(item.attributsLibIdattributs.idattributsLib))
            )
        }
        return {
            idLibSelect: idSelectArray
        }
    }

    /**
     * Test l'existence de la donnée
     * @param idType
     * @returns {Promise<any>}
     */
    getAllAtrib = async (idType, idLibAttribut = false) => {

        if (idLibAttribut) {
            var rechByIdLIb = '&attributsLibIdattributs=' + idLibAttribut
        } else {
            var rechByIdLIb = ""
        }

        if (this.state.type == 'pays') {
            var callApiData = await axios.get(API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + "&attributscolCom=" + this.state.templateAttribut + rechByIdLIb + '&attributscolStatut=1', {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });
        } else if (this.state.type == 'sejour') {
            var callApiData = await axios.get(API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + "&attributscolCom=" + this.state.templateAttribut + rechByIdLIb + '&attributscolStatut=1', {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });
        } else if (this.state.type == 'paysParent') {
            var callApiData = await axios.get(API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + "&attributscolCom=" + this.state.templateAttribut + rechByIdLIb + '&attributscolStatut=1', {
                headers: {
                    'Authorization': 'bearer ' + TOKENADMIN
                }
            });
        }

        return {
            total: callApiData.data['hydra:totalItems'],
            data: callApiData.data['hydra:member'],
        }
    }

    getAllAtribIdSejour = async (idType) => {


        if (this.state.type == 'pays') {
            var urlRequete = API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + '&attributscolIdpaysSejour=' + this.state.idPays + '&attributscolStatut=1'
        } else if (this.state.type == 'sejour') {
            var urlRequete = API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + '&attributscolIdsejour=' + this.state.idSejour + '&attributscolStatut=1'
        } else if (this.state.type == 'paysParent') {
            var urlRequete = API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + '&attributscolIdpays=' + this.state.idGpPays + '&attributscolStatut=1'
        }


        let callApiData = await axios.get(urlRequete, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        return {
            total: callApiData.data['hydra:totalItems'],
            data: callApiData.data['hydra:member'],
        }
    }

    getAllAtribSejour = async (idType, idLibAttribut) => {

        if (this.state.type == 'pays') {
            var urlRequete = API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + '&attributscolIdpaysSejour=' + this.state.idPays + '&attributsLibIdattributs=' + idLibAttribut
        } else if (this.state.type == 'sejour') {
            var urlRequete = API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + '&attributscolIdsejour=' + this.state.idSejour + '&attributsLibIdattributs=' + idLibAttribut
        } else if (this.state.type == 'paysParent') {
            var urlRequete = API_URI + '/api/attributs?attributsTypeIdattributsType=' + idType + '&attributscolIdpays=' + this.state.idGpPays + '&attributsLibIdattributs=' + idLibAttribut
        }


        let callApiData = await axios.get(urlRequete, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        return {
            total: callApiData.data['hydra:totalItems'],
            data: callApiData.data['hydra:member'],
        }
    }

    creatLst = async () => {
        let datav = await this.getAllAtrib(this.state.typeAttribut)


        if (datav.total > 0) {
            let datavmap = datav.data;
            var listeTree = []
            datavmap.map((item) =>
                listeTree.push(
                    {
                        title: item.attributsLibIdattributs.attributsLibLib,
                        key: String(item.attributsLibIdattributs.idattributsLib),
                    }
                )
            )
        }

        const treeDataFinal = [
            {
                title: this.state.titleListe,
                key: '0',
                disabled: true,
                children: listeTree,
            },
        ];

        this.setState({
            listeTree: treeDataFinal
        })
    }


    delById = async (id) => {
        await axios.delete(API_URI + '/api/attributs/' + id, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
    }

    delAllByType = async (idLibAttribut, idType) => {
        let dataAttr = await this.getAllAtribSejour(idType, idLibAttribut)
        let dataMap = dataAttr.data;


        dataMap.map((dataAttrv) =>
            //this.delById(dataAttrv.idattributs)
            this.disableEnableAttribut(dataAttrv.idattributs, 0)
        );


        //let callApiData = await axios.delete(API_URI + '/api/attributs?attributscolIdpaysSejour=' + this.state.idPays + '&attributsTypeIdattributsType=' + idType);
    }

    insertAttrib = async (idLib, idType) => {
        let data = {
            "attributscolVal": "",
            "attributscolStatut": 1,
            "attributscolCom": "",
            "attributscolValUk": "",
            "attributsLibIdattributs": "/api/attributs_libs/" + idLib,
            "attributsTypeIdattributsType": "/api/attributs_types/" + idType,
            "attributscolCat": this.state.catId,
        }

        if (this.state.type == 'pays') {
            data = {
                ...data,
                "attributscolIdpays": null,
                "attributscolIdpaysSejour": this.state.idPays,
                "attributscolIdsejour": null,
            }
        } else if (this.state.type == 'sejour') {
            data = {
                ...data,
                "attributscolIdpays": null,
                "attributscolIdpaysSejour": null,
                "attributscolIdsejour": this.state.idSejour,
            }
        } else if (this.state.type == 'paysParent') {
            data = {
                ...data,
                "attributscolIdpays": this.state.idGpPays,
                "attributscolIdpaysSejour": null,
                "attributscolIdsejour": null,
            }
        }
        console.log(data)

        const res = await axios.post(API_URI + '/api/attributs', data, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }

    updateAttrib = async (idAttrib, valueAttr) => {
        let data = {
            "attributscolVal": valueAttr,
        }
        const res = await axios.put(API_URI + '/api/attributs/' + idAttrib, data, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }

    disableEnableAttribut = async (idAttrib, attributscolStatut) => {
        let data = {
            "attributscolStatut": attributscolStatut,
        }

        const res = await axios.put(API_URI + '/api/attributs/' + idAttrib, data, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }

    onCheck = async (selectedKeys, info) => {
        if (info.checked) {
            //await this.delAllByType(info.node.key, this.state.typeAttribut)
            await this.delAllByType(info.node.key, this.state.typeAttribut);

            let testAttributExist = await this.getAllAtribSejour(this.state.typeAttribut, info.node.key);
            let testAttributExistv = testAttributExist.data;

            if (testAttributExistv[0] !== undefined) {
                await this.disableEnableAttribut(testAttributExistv[0].idattributs, 1);
            } else {
                await this.insertAttrib(info.node.key, this.state.typeAttribut)
            }

            //await this.insertAttrib(info.node.key, this.state.typeAttribut)
            //await this.disableEnableAttribut(info.node.key, this.state.typeAttribut,1);
        } else {
            //await this.disableEnableAttribut(info.node.key, this.state.typeAttribut,0);
            await this.delAllByType(info.node.key, this.state.typeAttribut);
        }
    }

    onChangeValue = (e) => {
        this.updateAttrib(e.target.id, e.target.value)
    }

    onChangeSelect = (value, e) => {
        this.updateAttrib(e['data-id'], value)
    }

    onSelect = async (selectedKeys, info) => {
        if (info.selected) {

            let dataAttr = await this.getAllAtribSejour(this.state.typeAttribut, info.node.key)
            let dataAttrTemplate = await this.getAllAtrib(this.state.typeAttribut, info.node.key)
            let dataMap = dataAttr.data;
            let dataTemplate = dataAttrTemplate.data;

            if (dataMap[0] && dataTemplate[0]) {
                const idAttribut = await dataMap[0].idattributs
                var option1Json = await dataTemplate[0].option1Json


                // si input
                if (typeof option1Json == 'undefined') {
                    var formAttributValue = await <div>
                        <Title level={3}>Valeur de cet attribut</Title>
                        <Input id={idAttribut} defaultValue={dataMap[0].attributscolVal} onChange={this.onChangeValue}
                               placeholder="Inserer la valeur ici"/>
                    </div>
                } else {
                    //dataMap[0].attributscolVal
                    const lsteOptionAttr = option1Json.map((data, index) => {
                            return <Option data-id={idAttribut} value={data.val}>{data.lib}</Option>
                        }
                    )

                    var formAttributValue = <div>
                        <Title level={3}>Valeur de cet attribut</Title>
                        <Select defaultValue={dataMap[0].attributscolVal} id={idAttribut} onChange={this.onChangeSelect}
                                style={{width: 200}}>
                            <Option value="">Choisir</Option>
                            {lsteOptionAttr}
                        </Select>
                    </div>
                }

                this.setState({valueZone: ""})
                this.setState({valueZone: formAttributValue})
            } else {
                const formAttributValue = <div>
                    <Alert message="Cet attribut n'a pas été coché" type="error"/>
                </div>
                this.setState({valueZone: formAttributValue})
            }
        } else {
            const formAttributValue = <div>
                <Alert message="Cliquez sur un attribut" type="info"/>
            </div>
            this.setState({valueZone: formAttributValue})
        }
    }

    render() {
        if (this.state.loading) {
            if (this.state.valueInsert) {
                return (
                    <div>
                        <Row>
                            <Col span={10}>
                                <Tree
                                    checkable={true}
                                    onCheck={this.onCheck}
                                    defaultExpandedKeys={['0']}
                                    defaultCheckedKeys={this.state.dataSelect}
                                    onSelect={this.onSelect}
                                    treeData={this.state.listeTree}
                                />
                            </Col>
                            <Col span={14}>
                                {this.state.valueZone}
                            </Col>
                        </Row>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Row>
                            <Col span={24}>
                                <Tree
                                    checkable={true}
                                    onCheck={this.onCheck}
                                    defaultExpandedKeys={['0']}
                                    defaultCheckedKeys={this.state.dataSelect}
                                    onSelect={this.onSelect}
                                    treeData={this.state.listeTree}
                                />
                            </Col>
                        </Row>
                    </div>
                )
            }

        } else {
            return (
                <div>Chargement</div>
            )
        }

    }
}

export default AttributsSejours;