import React from 'react'
import {Breadcrumb, Layout, Button, Table, PageHeader, Space, Tabs} from 'antd';
import FormPaysAdd from "./Form/FormPaysAdd";
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";

class PaysAdd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cat: this.props.match.params.cat
        }
    }

    render() {

        return (
            <Layout style={{minHeight: '100vh'}}>
                <HeaderTop/>
                <Layout>
                    <MenuSider/>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <div>
                            <Breadcrumb style={{margin: '16px 0'}}>
                                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item>Pays</Breadcrumb.Item>
                                <Breadcrumb.Item>Ajouter</Breadcrumb.Item>
                            </Breadcrumb>
                            <Layout
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >


                                <FormPaysAdd cat={this.state.cat}/>

                            </Layout>
                        </div>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

export default PaysAdd;


