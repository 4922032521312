import {INCREMENT, CREATBLOCTXT, CAT, GETLISTPAYS} from "../constants/ActionTypes";
import {API_URI} from "../constants/ApiConfig";
import React from "react";

//const axios = require('axios');
import axios from "axios";

export function incrementAction() {
    return {
        type: INCREMENT,
    };
}

export function getCats(nameCat) {

    let libCat = null;
    let permaLien = null;

    if (nameCat === 3) {
        libCat = 'Linguistique'
        permaLien = 'cour-de-langue'
    } else if (nameCat === 2) {
        libCat = 'Bénévolat'
        permaLien = 'sejour-benevolat'
    } else if (nameCat === 1) {
        libCat = 'Combiné'
        permaLien = 'sejour-langues-et-benevolat'
    } else {
        libCat = 'Non catégorisé'
        permaLien = 'null'
    }


    return {
        type: CAT,
        nameCat: libCat,
        permaLien : permaLien,
    };
}

export const blocText = geod => ({
    type: 'CREATBLOCTXT',
    geod: geod,
    indexoo: 15
});
