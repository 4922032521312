import React from 'react'
import Liste from "../pays/Liste";
import {Breadcrumb, Layout, Button, Table, Tag, Space, Tabs, PageHeader, Card, Divider, Col, Typography} from 'antd';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import ListeSupp from "./table/ListeSupp";
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";
import axios from "axios";
const {Content} = Layout;
const {TabPane} = Tabs;
const {Title, Text} = Typography
//const axios = require('axios');

class SuppPanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            idPays: this.props.match.params.id,
            cat: this.props.match.params.cat,
            dataPaysSejour: null,
            dataProgrammes: null,
            loading: false,
        }

    }

    async componentDidMount() {
        await this.detailsPaysSejour()

        await this.getProgrammes()

        this.setState({
            loading: true,
        })
    }


    detailsPaysSejour = async () => {

        let dataApi = await axios.get(API_URI + '/api/gp_pays_sejours/' + this.state.idPays, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        this.setState({
            dataPaysSejour: dataApi.data,
        })


    }


    getProgrammes = async () => {

        let getUR = API_URI + '/api/gp_sejours?order[h1Fr]=asc&cat=' + this.state.cat + '&codePays=' + this.state.dataPaysSejour.codePays;
        let dataReturn = []
        let dataApi = await axios.get(getUR, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        let dataApiR = dataApi.data['hydra:member'];


        this.setState({
            dataProgrammes: dataApiR
        })


    }

    listeSejours = () => {

        const listItems = this.state.dataProgrammes.map((dataSejour) =>
            <div className="site-card-border-less-wrapper">
                <Card bordered={false} style={{width: "100%"}} title={dataSejour.id + ' - ' + dataSejour.h1Fr} extra={[
                    <Link className="btn btn-primary"
                          to={`/supplements/add/sejour/${this.state.cat}/${this.state.idPays}/${dataSejour.id}`}>
                        <Button type="primary">Ajouter</Button>
                    </Link>,
                ]}>
                    <ListeSupp idSejour={dataSejour.id}/>
                </Card>
            </div>
        );

        return listItems


    }


    render() {

        if (this.state.loading) {
            return (
                <Layout style={{minHeight: '100vh'}}>
                    <HeaderTop/>
                    <Layout>
                        <MenuSider/>
                        <Layout style={{padding: '0 24px 24px'}}>
                            <div>
                                <Breadcrumb style={{margin: '16px 0'}}>
                                    <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                    <Breadcrumb.Item>Supplements</Breadcrumb.Item>
                                    <Breadcrumb.Item>Panel</Breadcrumb.Item>
                                </Breadcrumb>


                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 24,
                                        margin: 0,
                                        minHeight: 280,
                                    }}
                                >
                                    <PageHeader
                                        className="site-page-header"
                                        onBack={() => window.history.back()}
                                        title="Liste des suppléments"
                                        subTitle="Liste des suppléments liés au pays ou au séjour"
                                    />

                                    <Divider/>
                                    <Title id="anchor_txtsousmeteo" level={2}>Liste des supplements du pays</Title>
                                    <Text type="secondary">Ici vous retrouvez la liste des supplements liés au
                                        pays</Text>
                                    <Divider dashed="true"/>

                                    <div className="site-card-border-less-wrapper">
                                        <Card bordered={false} style={{width: "100%"}} title="Liste des dates du pays"
                                              extra={[
                                                  <Link className="btn btn-primary"
                                                        to={`/supplements/add/pays/${this.state.cat}/${this.state.idPays}`}>
                                                      <Button type="primary">Ajouter</Button>
                                                  </Link>,
                                              ]}>
                                            <ListeSupp idPays={this.state.idPays}/>
                                        </Card>
                                    </div>

                                    <Divider/>
                                    <Title id="anchor_txtsousmeteo" level={2}>Liste des supplements des séjours</Title>
                                    <Text type="secondary">Ici vous retrouvez la liste des supplements liés au
                                        séjour</Text>
                                    <Divider dashed="true"/>

                                    {this.listeSejours()}


                                </Content>
                            </div>
                        </Layout>
                    </Layout>
                </Layout>
            );
        } else {
            return (
                <div>Chargement...</div>
            )
        }

    }
}


export default SuppPanel;


