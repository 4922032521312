import React from 'react'
import {Layout, Row, Table, Tag, Col, Button} from 'antd';
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import {Link} from 'react-router-dom';
import axios from "axios";
const {Content} = Layout;
//const axios = require('axios');


async function getNewToken(){
    const res = await axios.post(API_URI + '/api/authentication_token', {
        "email": "momo",
        "password": "momo"
    });

   await localStorage.setItem('tokenAdmin', res.data.token);
}

export default async  function getToken() {

    //localStorage.removeItem('tokenAdmin');
    // tester si le token est défini
    if(!TOKENADMIN){
       //getNewToken()
    }
    getNewToken()
    
    var token = localStorage.getItem('tokenAdmin');
    console.log(token)
    return token
}




