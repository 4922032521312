import React from "react";
import {PageHeader, Skeleton, Avatar, Row, Col, Layout, Card, Tabs, Button} from "antd";
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import ProgrammeSeo from "./Form/ProgrammeSeo";
import TextProgrammes from "./TextProgrammes";
import ImagesProgrammes from "./ImagesProgrammes";
import AttributsSejours from "../attributs/AttributsSejours";
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";
import axios from "axios";
//const axios = require("axios")

class PanelProgrammes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataProgrammesDetails: [],
            dataPaysSejour: [],
            id: this.props.match.params.id,
            dataPays: this.props.dataPays,
            siteImages : false,
        }
    }

    async componentDidMount() {

        await this.getSiteImages()
        await this.getProgrammesDetails()
        await this.detailsPaysSejour()


        const {getCats, nameCatok} = this.props;
        let resultCat = getCats(this.state.dataProgrammesDetails.cat)

        this.setState({
            nameCat: resultCat.nameCat,
            permaLien: resultCat.permaLien,
        })


    }


    /**
     * Details du programme
     * @returns {Promise<void>}
     */
    getProgrammesDetails = async () => {

        const {id} = this.state;

        let dataReturn = []
        let dataApi = await axios.get(API_URI + '/api/gp_sejours/' + id, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        let dataApiR = dataApi.data;


        this.setState({
            dataProgrammesDetails: dataApiR,
        })

    }

    getSiteImages = async () => {

        const {id} = this.state;

        let dataReturn = []
        let dataApi = await axios.get(API_URI + '/api/gp_sejours/' + id+'/site_images', {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        let dataApiR = dataApi.data['hydra:member'];
        var arrayReturn = []


        var tifOptions = Object.keys(dataApiR).map(function(key) {
            var uniqKey = dataApiR[key]['uniqKey']
            arrayReturn.push(API_URI+'/'+dataApiR[key]['path']+'/'+dataApiR[key]['name'])

        });



        this.setState({
            siteImages: arrayReturn,
        })

    }

    detailsPaysSejour = async () => {

        let dataApi = await axios.get(API_URI + '/api/gp_pays_sejours?cat=' + this.state.dataProgrammesDetails.cat + '&order[nomPays]=asc&order&codePays=' + this.state.dataProgrammesDetails.codePays, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        let dataApiR = dataApi.data['hydra:member'];
        this.setState({
            dataPaysSejour: dataApiR[0],
            loading: true,
        })


    }

    callback(key) {
        console.log(key);
    }


    render() {

        const {loading, dataProgrammesDetails} = this.state;
        const {Header, Footer, Sider, Content} = Layout;
        const {TabPane} = Tabs;

        if (loading) {

            const routes = [
                {
                    path: 'index',
                    breadcrumbName: 'Bénévolat',
                },
                {
                    path: 'first',
                    breadcrumbName: dataProgrammesDetails.nomPays,
                },
                {
                    path: 'second',
                    breadcrumbName: dataProgrammesDetails.h1Fr,
                },
            ];


            console.log(this.state.siteImages[1])

            return (
                <Layout style={{minHeight: '100vh'}}>
                    <HeaderTop/>
                    <Layout>
                        <MenuSider/>
                        <Layout style={{padding: '0 24px 24px'}}>
                            <Content>
                                <PageHeader
                                    onBack={() => window.history.back()}
                                    className="site-page-header"
                                    title={dataProgrammesDetails.h1Fr}
                                    breadcrumb={{routes}}
                                    subTitle={dataProgrammesDetails.titleFr}
                                    extra={[
                                        <Button key="1" type="link"
                                                href={API_URI + "/" + this.state.permaLien + "/" + this.state.dataPaysSejour.permalienFr + "/" + this.state.dataProgrammesDetails.permalienFr + ".html"}
                                                target="_blank">
                                            Visiter la page sur le site
                                        </Button>,
                                    ]}
                                />

                                <Row>
                                    <Col span={11} style={{padding: "0 2em 0 0"}}>
                                        <img src={this.state.siteImages[0]} style={{width: '100%', maxHeight: '407px'}}/>
                                    </Col>
                                    <Col span={13}>
                                        <Card title="Seo" bordered={false} style={{width: "100%"}}>
                                            <ProgrammeSeo id={this.state.id} data={this.state.dataProgrammesDetails}/>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row style={{marginTop: "2em"}}>
                                    <Col span={24}>
                                        <Card style={{width: "100%"}}>
                                            <Tabs defaultActiveKey="1">
                                                <TabPane tab="Type" key="1">
                                                    <AttributsSejours
                                                        idSejour={this.state.id}
                                                        idPays={this.state.dataPaysSejour.id}
                                                        typeAttribut={6}
                                                        catId={this.state.dataProgrammesDetails.cat}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Attribut" key="2">
                                                    <AttributsSejours
                                                        idSejour={this.state.id}
                                                        idPays={this.state.dataPaysSejour.id}
                                                        typeAttribut={8}
                                                        valueInsert={true}
                                                        templateAttribut="template_sejour_infos"
                                                        catId={this.state.dataProgrammesDetails.cat}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Paramètres" key="5">
                                                    <AttributsSejours
                                                        idSejour={this.state.id}
                                                        idPays={this.state.dataPaysSejour.id}
                                                        typeAttribut={10}
                                                        valueInsert={true}
                                                        templateAttribut="template_sejour_parametre"
                                                        catId={this.state.dataProgrammesDetails.cat}
                                                    />
                                                </TabPane>
                                                <TabPane tab="Textes" key="3">
                                                    <TextProgrammes id={this.state.id}/>
                                                </TabPane>
                                                <TabPane tab="Images" key="4">
                                                    <ImagesProgrammes id={this.state.id}/>
                                                </TabPane>
                                            </Tabs>
                                        </Card>
                                    </Col>
                                </Row>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>

            )
        } else {
            return (
                <Content>

                </Content>
            )
        }

    }
}

export default PanelProgrammes