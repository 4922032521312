var useProdApi = true;

if (useProdApi) {
    //var CONST_API_URI = 'http://globalong.webexpertis.com';
    //var CONST_URL = 'http://globalong.webexpertis.com';
    var CONST_API_URI = 'https://www.globalong.com';
    var CONST_URL = 'https://www.globalong.com';
} else {
    var CONST_API_URI = 'http://www.globalongdev.com';
    var CONST_URL = 'http://www.globalongdev.com';
}

export const API_URI = CONST_API_URI;
export const URL = CONST_URL;

export const TOKENADMIN = localStorage.getItem('tokenAdmin');
