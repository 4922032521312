import React from "react";
import {Button, Form, Input, message, Skeleton} from "antd";
import {API_URI, TOKENADMIN} from "../../../constants/ApiConfig";
import axios from "axios";
//const axios = require('axios');

const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 14},
};
const tailLayout = {
    wrapperCol: {offset: 4, span: 22},
};


class ProgrammeSeo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingApiData: true,
            id: this.props.id,
            dataProgramme: this.props.data,

        }
    }

    loadMessage = () => {
        const key = 'updatable';
        message.loading({ content: 'Loading...', key });
        setTimeout(() => {
            message.success({ content: 'Loaded!', key, duration: 2 });
        }, 1000);
    }

    updateForm = async (value) => {

        const res = await axios.put(API_URI + '/api/gp_sejours/' + this.state.id, {
            "h1Fr": value.seo.h1Fr,
            "titleFr": value.seo.titleFr,
            "permalienFr": value.seo.permalienFr,
            "metaDescFr": value.seo.metaDescFr
        }, {
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }
        });

        this.loadMessage()

        return {
            'data': res.data,
        }
    }

    render() {

        return (
            <Form
                {...layout}
                name="basic"
                initialValues={{remember: true}}
                onFinish={this.updateForm}
            >
                <Form.Item
                    label="Permalien"
                    name={['seo', 'permalienFr']}
                    rules={[{required: true, message: 'Merci de compléter cette information'}]}
                    initialValue={this.state.dataProgramme.permalienFr}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Meta Title"
                    name={['seo', 'titleFr']}
                    rules={[{required: true, message: 'Merci de compléter cette information'}]}
                    initialValue={this.state.dataProgramme.titleFr}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Meta Description"
                    name={['seo', 'metaDescFr']}
                    rules={[{required: true, message: 'Merci de compléter cette information'}]}
                    initialValue={this.state.dataProgramme.metaDescFr}
                >
                    <Input.TextArea/>
                </Form.Item>

                <Form.Item
                    label="Titre H1"
                    name={['seo', 'h1Fr']}
                    rules={[{required: true, message: 'Merci de compléter cette information'}]}
                    initialValue={this.state.dataProgramme.h1Fr}
                >
                    <Input/>
                </Form.Item>


                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        )
    }
}


export default ProgrammeSeo;