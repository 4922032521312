import React from "react";
import {Modal, Button} from "antd";
import {Redirect} from "react-router-dom";
import {API_URI, TOKENADMIN} from "../../../constants/ApiConfig";
import axios from "axios";
//const axios = require('axios');

class FormAddSejour extends React.Component {

    state = {visible: false};

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
            redirect: null,
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = async e => {
        console.log(e);
        this.setState({
            visible: false,
        });

        let insert = await this.insertSejour()

        let lastIdSejour = Number(insert.data.id)

        this.setState({
            redirect: '/programmes/Panel/' + lastIdSejour
        })

    }

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    }

    insertSejour = async () => {

        const res = await axios.post(API_URI + '/api/gp_sejours', {
            "cat": this.state.data.cat,
            "h1Fr": "",
            "t1Fr": "",
            "bloc1Fr": "",
            "h1En": "",
            "t1En": "",
            "bloc1En": "",
            "codePays": this.state.data.codePays,
            "nomPays": this.state.data.nomPays,
            "idphoto": "",
            "visite": 0,
            "titleFr": "",
            "titleEn": "",
            "permalienFr": "",
            "permalienEn": "",
            "metaDescFr": "",
            "metaDescEn": "",
            "imgDescFr": "",
            "imgDescEn": "",
            "gpSejoursStatut": 0
        }, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }


    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }

        return (
            <div style={{marginBottom:"3em", textAlign:"right"}}>
                <Button type="primary" onClick={this.showModal}>
                    Ajouter un séjour
                </Button>
                <Modal
                    title="Ajouter un séjour"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <p>Voulez-vous ajouter un séjour à ce pays ?</p>
                </Modal>
            </div>
        )
    }
}

export default FormAddSejour