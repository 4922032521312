import React from 'react'
import {Breadcrumb, Layout, Col, Row} from 'antd';
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";
import PropTypes from 'prop-types'
import {getCat} from "../../actions";
import getToken from "../api/getToken";

import {TOKENADMIN} from "../../constants/ApiConfig";
import axios from "axios";

//const axios = require('axios');
const {Content} = Layout;

class Dashboard extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            unspalshResponse: false,
        }
    }

    async componentDidMount() {

        await this.getUnsplash()
       // await getToken();

        //alert(TOKENADMIN);


    }

    getUnsplash = async () => {

        try {
            const response = await axios.get('https://api.unsplash.com/photos/random/?client_id=16b1f5aeced2f198bd6cfb172a951862d1870e1b78e0a64fee6080d488e4fa8b&query=travel,city&orientation=landscape',{
                headers: {
                    'Authorization': 'bearer '+ TOKENADMIN
                }});

            this.setState(
                {
                    loading: true,
                    unspalshResponse: response.data.urls.raw
                })
        } catch (error) {
            this.setState(
                {
                    loading: true,
                    unspalshResponse: ''
                })
        }

    }

    clicIncrementation = () => {

        const {value, incrementAction, blocText, geodok, getCats, nameCatok} = this.props;
        incrementAction()
        blocText({title: 'I am a geo dude!'})
        getCats(3)
        console.log(geodok)
        console.log(nameCatok)

        console.log(this.props.nameCatok)

    }

    render() {
        if (this.state.loading) {
            return (
                <Layout style={{ minHeight: '100vh' }}>
                    <HeaderTop/>
                    <Layout>
                        <MenuSider />
                        <Layout style={{padding: '0 24px 24px'}}>
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 0,
                        margin: 0,
                        minHeight: 400,
                        width: "100%",
                        backgroundImage: "url(" + this.state.unspalshResponse + ")",
                        backgroundPosition: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >

                    <div style={{}}>
                        <div style={{
                            width: "700px",
                            height: "auto",
                            padding: "30px",
                            backgroundColor: "#FFFFFF",
                        }}>
                            Hello ;)

                        </div>
                    </div>

                </Content>
                        </Layout>
                    </Layout>
                </Layout>
            )
        } else {
            return (
                'Chargement...'
            )
        }


    }
}

/*
const mapStateToProps = (state) => ({
    value: state.value,
});



const mapDispatchToProps = (dispatch) => ({
    incrementAction: () => dispatch(incrementAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
*/
Dashboard.propTypes = {
    incrementAction: PropTypes.func.isRequired
}

export default Dashboard;


