import { connect } from 'react-redux'
import PaysPanel from '../components/pays/PaysPanel'
import {getCats,getListPays} from '../actions'

const mapStateToProps = (state) => ({
    nameCatok: state.nameCatok,
    permaLien :state.permaLien,
});


const mapDispatchToProps = (dispatch) => ({
    getCats: (x) => dispatch(getCats(x)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaysPanel)