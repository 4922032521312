import { connect } from 'react-redux'
import Pays from '../components/pays/Pays'
import {blocText, incrementAction} from '../actions'

//export default connect(null, {  })(Pays)


const mapStateToProps = state => ({
    geodok: state.geodok,
    value: state.value,
});


const mapDispatchToProps = (dispatch) => ({
    blocText: () => dispatch(blocText()),
    incrementAction: () => dispatch(incrementAction()),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pays)