import React from 'react'
import Liste from "../pays/Liste";
import {Breadcrumb, Layout, Button, Table, Tag, Space, Tabs, PageHeader} from 'antd';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";

const {Content} = Layout;
const {TabPane} = Tabs;


class Packs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cat: 2,
            pathPage: '/packs/panel/2/'
        }

    }

    componentDidMount() {

        //this.props.blocText({title: 'I am a geo dude!'})

        //this.props.incrementAction();

        console.log(this.props)

    }

    catChange = (cat) => {


        this.setState({
            cat: cat,
            pathPage: '/packs/panel/' + cat + '/'
        })
    }

    render() {

        const {value, geodok, geodoks} = this.props;

        return (
            <Layout style={{minHeight: '100vh'}}>
                <HeaderTop/>
                <Layout>
                    <MenuSider/>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <div>
                            <Breadcrumb style={{margin: '16px 0'}}>
                                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item>Supp</Breadcrumb.Item>
                            </Breadcrumb>


                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                <PageHeader
                                    className="site-page-header"
                                    onBack={() => window.history.back()}
                                    title="Supp séjours"
                                    subTitle="Liste des pays pour l'application d'un pack jusqu'au séjour"
                                />

                                <Tabs defaultActiveKey="1" onChange={key => this.catChange(key)}>
                                    <TabPane tab="Bénévolat" key="1">
                                        <Liste cat='2' pathPanel="/packs/panel/2/"/>
                                    </TabPane>
                                    <TabPane tab="Linguistique" key="2">
                                        <Liste cat='3' pathPanel="/packs/panel/3/"/>
                                    </TabPane>
                                    <TabPane tab="Combiné" key="3">
                                        <Liste cat='1' pathPanel="/packs/panel/1/"/>
                                    </TabPane>
                                </Tabs>
                            </Content>
                        </div>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}


export default Packs;


