import React from "react";
import {List, Typography, Divider} from 'antd';
import {API_URI,TOKENADMIN} from "../../constants/ApiConfig";
import {Link} from "react-router-dom";
import axios from "axios";
//const axios = require("axios")
const { Text } = Typography;

class ListeProgrammes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dataProgrammes: [],
            idCat: this.props.idCat,
            codePays: this.props.codePays,
        }

    }

    /**
     * Obtenir la liste des programmes au format de la liste
     * @returns {Promise<void>}
     */
    getProgrammes = async () => {

        let getUR = API_URI + '/api/gp_sejours?order[h1Fr]=asc&cat=' + this.state.idCat + '&codePays=' + this.state.codePays;
        let dataReturn = []
        let dataApi = await axios.get(getUR,{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});
        let dataApiR = dataApi.data['hydra:member'];
        dataApiR.map((data) =>
            dataReturn.push({title: data.h1Fr + ' - ' + data.t1Fr, id: data.id,statut:data.gpSejoursStatut})
        )

        this.setState({
            dataProgrammes: dataReturn
        })


    }


    componentDidMount() {
        this.getProgrammes()
    }

    itemAff = (item) => {

        if(item.statut == 0){
            return <List.Item>
                <Link className="btn btn-primary" to={`/programmes/Panel/${item.id}`}>
                    <Text type="danger">{item.title}</Text>
                </Link>
            </List.Item>
        }else{
            return <List.Item>
                <Link className="btn btn-primary" to={`/programmes/Panel/${item.id}`}>
                    {item.title}
                </Link>
            </List.Item>
        }
    }


    render() {

        return (

            <List
                bordered
                dataSource={this.state.dataProgrammes}
                renderItem={item => this.itemAff(item)}
            />

        )
    }
}


export default ListeProgrammes;