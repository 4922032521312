import React from 'react'
import Liste from './Liste'
import {Breadcrumb, Layout, Button, Table, Tag, Space, Tabs, PageHeader} from 'antd';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";

const {Content} = Layout;
const {TabPane} = Tabs;


class Pages extends React.Component {

    constructor(props) {
        super(props);


    }

    componentDidMount() {


    }


    render() {

        const {value, geodok, geodoks} = this.props;

        return (
            <Layout style={{minHeight: '100vh'}}>
                <HeaderTop/>
                <Layout>
                    <MenuSider/>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <div>
                            <Breadcrumb style={{margin: '16px 0'}}>
                                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item>Pages</Breadcrumb.Item>
                            </Breadcrumb>


                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                <PageHeader
                                    className="site-page-header"
                                    onBack={() => window.history.back()}
                                    title="Pages CMS"
                                    subTitle="Liste des pages du site"

                                />

                                <div>
                                    <Liste/>
                                </div>
                            </Content>
                        </div>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}


export default Pages;


