import React from 'react'
import Liste from './Liste'
import {Breadcrumb, Layout, Button, Table, Tag, Space, Tabs, PageHeader, Card} from 'antd';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {API_URI, TOKENADMIN} from "../../constants/ApiConfig";
import ListeBlocs from "./listeBlocs";
import AttributsSejours from "../attributs/AttributsSejours";
import TextProgrammes from "../programmes/TextProgrammes";
import ImagesProgrammes from "../programmes/ImagesProgrammes";
import HeaderTop from "../HeaderTop";
import MenuSider from "../MenuSider";
import axios from "axios";

const {Content} = Layout;
const {TabPane} = Tabs;
//const axios = require("axios")

class PagePanel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            data: null,
            loading: false,

        }

    }

    componentDidMount() {
        this.details()

    }

    details = async () => {

        let dataApi = await axios.get(API_URI + '/api/pages/' + this.state.id, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });
        let dataApiR = dataApi.data['hydra:member'];
        this.setState({
            data: dataApiR,
            loading: true,
        })
    }


    render() {

        const {value, geodok, geodoks} = this.props;

        return (
            <Layout style={{minHeight: '100vh'}}>
                <HeaderTop/>
                <Layout>
                    <MenuSider/>
                    <Layout style={{padding: '0 24px 24px'}}>
                        <div>


                            <Breadcrumb style={{margin: '16px 0'}}>
                                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item>Panel de la page</Breadcrumb.Item>
                            </Breadcrumb>


                            <Content
                                className="site-layout-background"
                                style={{
                                    padding: 24,
                                    margin: 0,
                                    minHeight: 280,
                                }}
                            >
                                <PageHeader
                                    className="site-page-header"
                                    onBack={() => window.history.back()}
                                    title="Pages CMS"
                                    subTitle="Liste des pages du site"

                                />

                                <div>
                                    <Card style={{width: "100%"}}>
                                        <Tabs defaultActiveKey="1">
                                            <TabPane tab="Textes" key="1">
                                                <ListeBlocs id={this.state.id} typeListe="texte"/>
                                            </TabPane>
                                            <TabPane tab="Images" key="2">
                                                <ListeBlocs id={this.state.id} typeListe="image"/>
                                            </TabPane>

                                        </Tabs>
                                    </Card>


                                </div>
                            </Content>
                        </div>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}


export default PagePanel;


