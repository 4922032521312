import React from "react";
import BlocImages from "../blocs/BlocImages";
import {Divider, Typography} from "antd";
const {Title, Text} = Typography;

class ImagesProgrammes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
        }

    }

    componentDidMount() {
        console.log('ID :'+this.state.id)
    }

    render() {
        return (
            <diV>
                <BlocImages
                    uniqKey="immprog"
                    height={1104}
                    width={966}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg=""
                />

                <BlocImages
                    uniqKey="immprog2"
                    height={1104}
                    width={966}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg=""
                />

                <BlocImages
                    uniqKey="img_pourqui"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg=""
                />

                <BlocImages
                    uniqKey="img_leprojet"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg=""
                />

                <BlocImages
                    uniqKey="img_hebergemenrrepas"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg=""
                />

                <Divider/>
                <Title level={2}>Accordéon : gallerie</Title>
                <Text type="secondary">Gallerie dans l'accordéon</Text>
                <Divider dashed="true"/>

                <BlocImages
                    uniqKey="gal_1"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg="gal1"
                />

                <BlocImages
                    uniqKey="gal_2"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg="gal1"
                />

                <BlocImages
                    uniqKey="gal_3"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg="gal1"
                />

                <BlocImages
                    uniqKey="gal_4"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg="gal1"
                />

                <BlocImages
                    uniqKey="gal_5"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg="gal1"
                />

                <BlocImages
                    uniqKey="gal_6"
                    height={240}
                    width={370}
                    title=""
                    description=""
                    typeIndex="idSejour"
                    id={this.state.id}
                    groupImg="gal1"
                />
            </diV>
        )
    }
}

export default ImagesProgrammes