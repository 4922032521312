import React from 'react'
import Liste from './Liste'
import {Breadcrumb, Layout, Button, Table, Tag, Space, Tabs, PageHeader} from 'antd';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {API_URI,TOKENADMIN} from "../../constants/ApiConfig";
import BlocTxt from "../blocs/BlocTxt";
import BlocImages from "../blocs/BlocImages";
import axios from "axios";

const {Content} = Layout;
const {TabPane} = Tabs;
//const axios = require("axios")

class ListeBlocs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            data: null,
            loading : false,
            typeListe : this.props.typeListe,

        }

    }

   async componentDidMount() {
       await  this.liste()

    }

    liste = async () => {

        if(this.state.typeListe == 'texte'){
            var dataApi = await axios.get(API_URI + '/api/site_textes?page=' + this.state.id,{
                headers: {
                    'Authorization': 'bearer '+ TOKENADMIN
                }});
        }else if(this.state.typeListe == 'image'){
            var dataApi = await axios.get(API_URI + '/api/site_images?page=' + this.state.id,{
                headers: {
                    'Authorization': 'bearer '+ TOKENADMIN
                }});
        }

        let dataApiR =  dataApi.data['hydra:member'];
        this.setState({
            data: dataApiR,
           loading: true,
        })
    }


    render() {

        const {value, geodok, geodoks} = this.props;

        if(this.state.loading ){

            if(this.state.typeListe == 'texte'){
                var listItems = this.state.data.map((data) =>
                    <div>
                        <BlocTxt
                            title={data.info}
                            description=""
                            inputName={data.uniqKey}
                            type={data.type}
                            uniqKey={data.uniqKey}
                            typeIndex="page"
                            id={this.state.id}
                        />
                    </div>
                );
            }else if(this.state.typeListe == 'image'){
                var listItems = this.state.data.map((data) =>
                    <div>
                        <BlocImages
                            uniqKey={data.uniqKey}
                            height={240}
                            width={370}
                            title=""
                            description=""
                            typeIndex="page"
                            id={this.state.id}
                            groupImg=""
                        />
                    </div>
                );
            }

            return (
                <div>
                    {listItems}
                </div>
            )

        }else{
            return (
                <div>
                    Loading...
                </div>
            );
        }




    }
}


export default ListeBlocs;


