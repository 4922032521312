import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';
import {Layout} from 'antd';
import HeaderTop from '../containers/HeaderTop'
import MenuSider from '../containers/MenuSider'
import Dashboard from '../containers/Dashboard'
import PaysPanel from '../containers/PaysPanel'
import Pays from '../containers/Pays'
import PaysAdd from "./pays/PaysAdd"
import PanelProgrammes from "../containers/PanelProgrammes"
import IndexParametres from "../containers/ParametreIndex"


import Packs from "./packs/Packs"
import PackPanel from "./packs/PackPanel"
import AddPack from "./packs/Form/AddPack"

import Dates from "./dates/Dates";
import DatePanel from "./dates/DatePanel"
import AddDate from "./dates/Form/AddDate"

import Supp from "./supplements/Supp";
import SuppPanel from "./supplements/SuppPanel"
import AddSupp from "./supplements/Form/AddSupp"

import Pages from "./pages/Pages";
import PagePanel from "./pages/PagePanel";
import IdentUser from "./home/IdentUser";
import EditPack from "./packs/Form/EditPack";


const App = () => (
    <Router>

        <Route exact path="/" component={IdentUser}/>
        <Route exact path="/dashboard" component={Dashboard}/>
        <Route exact path="/pays" component={Pays}/>
        <Route exact path="/pays/panel/:id" component={PaysPanel}/>
        <Route exact path="/pays/add/:cat" component={PaysAdd}/>

        <Route exact path="/programmes/panel/:id" component={PanelProgrammes}/>

        <Route exact path="/packs" component={Packs}/>
        <Route exact path="/packs/panel/:cat/:id" component={PackPanel}/>
        <Route exact path="/packs/add/:type/:cat/:id" component={AddPack}/>
        <Route exact path="/packs/edit/:id" component={EditPack}/>
        <Route exact path="/packs/add/:type/:cat/:id/:idSejour" component={AddPack}/>

        <Route exact path="/dates" component={Dates}/>
        <Route exact path="/dates/panel/:cat/:id" component={DatePanel}/>
        <Route exact path="/dates/add/:type/:cat/:id" component={AddDate}/>
        <Route exact path="/dates/add/:type/:cat/:id/:idSejour" component={AddDate}/>

        <Route exact path="/supplements" component={Supp}/>
        <Route exact path="/supplements/panel/:cat/:id" component={SuppPanel}/>
        <Route exact path="/supplements/add/:type/:cat/:id" component={AddSupp}/>
        <Route exact path="/supplements/add/:type/:cat/:id/:idSejour" component={AddSupp}/>

        <Route exact path="/pages" component={Pages}/>
        <Route exact path="/pages/panel/:id" component={PagePanel}/>

        <Route exact path="/parametres/home" component={IndexParametres}/>

        <Route exact path="/exit" component={IdentUser}/>
    </Router>

)

export default App
