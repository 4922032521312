import React from 'react';
import {Redirect} from "react-router-dom";
import {Breadcrumb, Layout, Button, Card, AutoComplete, Form, Select, PageHeader} from 'antd';
import {API_URI,TOKENADMIN} from "../../../constants/ApiConfig";
import axios from "axios";
//const axios = require('axios');
const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 14},
};
const tailLayout = {
    wrapperCol: {offset: 4, span: 22},
};

const {Option} = Select;


class FormPaysAdd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cat: Number(this.props.cat),
            listePaysItems: null,
            redirect: null,
        }

    }


    getListPays = async () => {
        let dataApi = await axios.get(API_URI + '/api/gp_pays?order[paysFr]=asc',{
            headers: {
                'Authorization': 'bearer '+ TOKENADMIN
            }});

        let dataReponse = dataApi.data['hydra:member'];

        // const listPays = [{text: "4", value: "aa"}, {text: "44", value: "aa"}];
        const listPaysItems = dataReponse.map((data) =>
            <Option value={data.id + "|" + data.codePays}>{data.paysFr}</Option>
        );

        this.setState({
            listePaysItems: listPaysItems
        })
    }

    componentDidMount() {
        this.getListPays()
    }

    onChange = async (value, opt) => {

        let nomPays = opt.children;
        let splitValue = value.split('|')
        let idPays = splitValue[0]
        let codePays = splitValue[1]

        let retourInsert = await this.insertPaysSejour(codePays, nomPays)

        console.log(retourInsert.data.id)

        let lastIdPaysSejour = Number(retourInsert.data.id)

        this.setState({
            redirect: '/Pays/Panel/' + lastIdPaysSejour
        })
    }

    insertPaysSejour = async (codePays, nomPays) => {



        const res = await axios.post(API_URI + '/api/gp_pays_sejours', {
            "cat": this.state.cat,
            "h1Fr": "",
            "bloc1Fr": "",
            "bloc2Fr": "",
            "bloc3Fr": "",
            "bloc4Fr": "",
            "t1Fr": "",
            "t2Fr": "",
            "t3Fr": "",
            "t4Fr": "",
            "h1En": "",
            "bloc1En": "",
            "bloc2En": "",
            "bloc3En": "",
            "bloc4En": "",
            "t1En": "",
            "t2En": "",
            "t3En": "",
            "t4En": "",
            "nomPays": nomPays,
            "codePays": codePays,
            "titleFr": "",
            "titleEn": "",
            "permalienFr": "",
            "permalienEn": "",
            "metaDescFr": "",
            "metaDescEn": "",
            "imgDescFr": "",
            "imgDescEn": "",
            "image": ""
        }, {
            headers: {
                'Authorization': 'bearer ' + TOKENADMIN
            }
        });

        return {
            'data': res.data,
        }
    }


    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect}/>
        }


        return (
            <div>
                <Layout
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        margin: 0,
                        minHeight: 280,
                    }}
                >

                    <Form
                        {...layout}
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={this.updateForm}
                    >


                        <PageHeader
                            className="site-page-header"
                            onBack={() => window.history.back()}
                            title="Ajouter un pays séjour"
                            subTitle="Ajouter un pays pour les séjours"
                        />
                        <Card title="Liste des pays disponibles">
                            <Select
                                showSearch
                                style={{width: 200}}
                                placeholder="Choisir un pays à lier"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={this.onChange}
                            >
                                {this.state.listePaysItems}
                            </Select>
                        </Card>


                    </Form>

                </Layout>
            </div>
        );
    }
}

export default FormPaysAdd;


