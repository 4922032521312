import React from "react";
import BlocTxt from "../blocs/BlocTxt";
import {Col, Divider, Typography} from "antd";

class TextProgrammes extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
        }
    }

    render() {

        const {Title, Text} = Typography;

        return(
            <div>

                <Title level={2}>Liste</Title>
                <Text type="secondary">Données nécessaires pour la liste (ex: petite description qui sera affichée sur la liste des séjours sur une page pays)</Text>
                <Divider/>

                <BlocTxt
                    title="Sous-titre"
                    description=""
                    inputName="liste_sous_titre"
                    type="brute"
                    uniqKey="liste_sous_titre"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Description"
                    description=""
                    inputName="liste_desc"
                    type="brute"
                    uniqKey="liste_desc"
                    typeIndex="idSejour"
                    id={this.state.id}
                />



                <Title level={2}>Introduction</Title>
                <Text type="secondary">Bloc Texte introduction</Text>
                <Divider/>

                <BlocTxt
                    title="Sous-titre du paragraphe"
                    description=""
                    inputName="text_1_titre"
                    type="brute"
                    uniqKey="text_1_titre"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Texte intro"
                    description=""
                    inputName="text_1"
                    type="richText"
                    uniqKey="text_1"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <Divider/>
                <Title id="anchor_accordeon"  level={2}>Accordéon</Title>
                <Text type="secondary">Accordéon qui est composé de plusieurs partie "Pour qui", "Lieux" etc...
                    etc...</Text>
                <Divider dashed="true"/>

                <BlocTxt
                    title="Le projet : Description, introduction, premiers jours"
                    description=""
                    inputName="accordeon_3"
                    uniqKey="accordeon_3"
                    type="richText"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Le projet : Tâches"
                    description=""
                    inputName="accordeon_4"
                    uniqKey="accordeon_4"
                    type="richText"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Pour qui : Profil des bénévoles"
                    description=""
                    inputName="accordeon_1"
                    uniqKey="accordeon_1"
                    type="richText"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Pour qui : Liste conditions"
                    description=""
                    inputName="accordeon_2"
                    uniqKey="accordeon_2"
                    type="richText"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Les lieux : Description"
                    description=""
                    inputName="accordeon_5"
                    uniqKey="accordeon_5"
                    type="richText"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Hébergement et repas : Description logement, repas, wifi"
                    description=""
                    inputName="accordeon_6"
                    uniqKey="accordeon_6"
                    type="richText"
                    typeIndex="idSejour"
                    id={this.state.id}
                />

                <BlocTxt
                    title="Sécurité : Infos sécurité"
                    description=""
                    inputName="accordeon_7"
                    uniqKey="accordeon_7"
                    type="richText"
                    typeIndex="idSejour"
                    id={this.state.id}
                />


            </div>
        )
    }

}

export default TextProgrammes